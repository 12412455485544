import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { App } from "./App";
import { AppVR } from "./vr/AppVR";

export default function RouteApp() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/vr">
            <AppVR />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
