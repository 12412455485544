import * as THREE from "three";
import create from "zustand";
//import hdri from "../assets/hdri/Ridgecrest_Road_1k_Bg.jpg";

import audio1 from "../assets/sounds/letter_a.m4a";
import audio2 from "../assets/sounds/letter_b.m4a";
import audio3 from "../assets/sounds/letter_c.m4a";
import audio4 from "../assets/sounds/letter_d.m4a";
import audio5 from "../assets/sounds/letter_e.m4a";
import audio6 from "../assets/sounds/letter_f.m4a";
import audio7 from "../assets/sounds/letter_g.m4a";
import audio8 from "../assets/sounds/letter_h.m4a";
import audio9 from "../assets/sounds/letter_i.m4a";
import audio10 from "../assets/sounds/letter_j.m4a";
import audio11 from "../assets/sounds/letter_k.m4a";
import audio12 from "../assets/sounds/letter_l.m4a";
import audio13 from "../assets/sounds/letter_m.m4a";
import audio14 from "../assets/sounds/letter_n.m4a";
import audio15 from "../assets/sounds/letter_o.m4a";
import audio16 from "../assets/sounds/letter_p.m4a";
import audio17 from "../assets/sounds/letter_q.m4a";
import audio18 from "../assets/sounds/letter_r.m4a";
import audio19 from "../assets/sounds/letter_s.m4a";
import audio20 from "../assets/sounds/letter_t.m4a";
import audio21 from "../assets/sounds/letter_u.m4a";
import audio22 from "../assets/sounds/letter_v.m4a";
import audio23 from "../assets/sounds/letter_w.m4a";
import audio24 from "../assets/sounds/letter_x.m4a";
import audio25 from "../assets/sounds/letter_y.m4a";
import audio26 from "../assets/sounds/letter_z.m4a";
import ambient1 from "../assets/sounds/ambient_loop01.mp3";
import ambient2 from "../assets/sounds/ambient_loop02_bi.mp3";
import transition1 from "../assets/sounds/transition_01.mp3";
import transition2 from "../assets/sounds/transition_02.mp3";
import whoosh from "../assets/sounds/whoosh_02.mp3";

const transitionURLs = [transition1, transition2];
const musicURLs = [ambient1, ambient2];
const soundURLs = [
  audio1,
  audio2,
  audio3,
  audio4,
  audio5,
  audio6,
  audio7,
  audio8,
  audio9,
  audio10,
  audio11,
  audio12,
  audio13,
  audio14,
  audio15,
  audio16,
  audio17,
  audio18,
  audio19,
  audio20,
  audio21,
  audio22,
  audio23,
  audio24,
  audio25,
  audio26,
];

const audioBuffers = {};
const musicBuffers = {};
const transitionBuffers = {};
const audioListener = new THREE.AudioListener();
const audioLoader = new THREE.AudioLoader();
const positionalPlayers = [];
const musicPlayers = [];
const transitionPlayers = [];
const whooshPlayer = new THREE.PositionalAudio(audioListener);

const startAudioContext = () => {
  // create empty buffer
  var buffer = whooshPlayer.context.createBuffer(1, 1, 22050);
  var source = whooshPlayer.context.createBufferSource();
  source.buffer = buffer;

  // connect to output (your speakers)
  source.connect(whooshPlayer.context.destination);

  // play the file
  source.start(0);

  window.removeEventListener("keypress", startAudioContext);
};

window.addEventListener("keypress", startAudioContext, false);

let audioLoadProgress = 0;

soundURLs.forEach((url, i) => {
  audioLoader.load(url, (b) => {
    audioBuffers[i] = b;
  });
});
transitionURLs.forEach((url, i) => {
  audioLoader.load(url, (b) => {
    transitionBuffers[i] = b;
  });
});
musicURLs.forEach((url, i) => {
  audioLoader.load(url, (b) => {
    musicBuffers[i] = b;
  });
});
audioLoader.load(whoosh, (b) => {
  whooshPlayer.setBuffer(b);
  whooshPlayer.setLoop(true);
  whooshPlayer.setVolume(0);
  whooshPlayer.pause();
});

for (let i = 0; i < 26; i++) {
  const posSFX = new THREE.PositionalAudio(audioListener);
  posSFX.setRefDistance(3);
  posSFX.setVolume(0.8);
  posSFX.setRolloffFactor(0.5);
  posSFX.setLoop(false);
  positionalPlayers.push(posSFX);
}
for (let i = 0; i < 2; i++) {
  const music = new THREE.Audio(audioListener);
  music.setVolume(0);
  music.setLoop(true);
  musicPlayers.push(music);
}
for (let i = 0; i < 2; i++) {
  const transition = new THREE.Audio(audioListener);
  transition.setVolume(0.3);
  transition.setLoop(false);
  transitionPlayers.push(transition);
}

function checkAudioBuffers() {
  let checkSum = 0;
  const count = soundURLs.length + musicURLs.length + transitionURLs.length + 1;

  Object.keys(audioBuffers).forEach((k) => {
    if (audioBuffers[k] !== undefined) checkSum += 1;
  });
  Object.keys(musicBuffers).forEach((k) => {
    if (musicBuffers[k] !== undefined) checkSum += 1;
  });
  Object.keys(transitionBuffers).forEach((k) => {
    if (transitionBuffers[k] !== undefined) checkSum += 1;
  });
  if (whooshPlayer.buffer !== undefined) checkSum += 1;

  audioLoadProgress = checkSum / count;
  if (checkSum !== count) requestAnimationFrame(checkAudioBuffers);
  // ---> loading complete
  else {
    // positional
    for (let i = 0; i < positionalPlayers.length; i++) {
      positionalPlayers[i].setBuffer(audioBuffers[i]);
    }
    // music
    for (let i = 0; i < musicPlayers.length; i++) {
      musicPlayers[i].setBuffer(musicBuffers[i]);
      musicPlayers[i].setVolume(0);
    }
    // transitions
    for (let i = 0; i < transitionPlayers.length; i++) {
      transitionPlayers[i].setBuffer(transitionBuffers[i]);
    }
  }
}

checkAudioBuffers();

const letters = "abcdefghijklmnopqrstuvwxyz";
const lettersArray = letters.split("");
lettersArray.push("Space");

const dotsRevealed = {};
const resetDotsRevealed = () => {
  for (let i = 0; i < 25; i++) {
    dotsRevealed[i] = false;
  }
};
resetDotsRevealed();

let guid = 1;
const meshLoadChecksum = 26;

const useStore = create((set, get) => {
  return {
    letters: randomData(26),
    //hdri: getHdri(),
    playMusic: playMusic,
    audioBuffers: audioBuffers,
    audioListener: audioListener,
    positionalSounds: positionalPlayers,
    startTransitionSound: () => transitionPlayers[0].play(),
    psychoModeTransitionSound: () => transitionPlayers[1].play(),
    rotationSoundPlayer: whooshPlayer,
    lettersArray: lettersArray,
    postEffect: false,
    activatedElements: new Array(26).fill(false),
    activeElement: -1,
    setActiveElement: (index) => {
      set((state) => ({
        activeElement: index,
        activatedElements: setActivated(state.activatedElements, index),
        postEffect: state.activatedElements.every((e) => e === true),
      }));
    },
    revealingElement: -1,
    setRevealingElement: (index) => {
      set({ revealingElement: index });
    },
    activeColor: new THREE.Color("white"),
    setActiveColor: (hex) =>
      set({ activeColor: new THREE.Color(hex).convertSRGBToLinear() }),
    revealPosition: [0, 0, 0],
    setRevealPosition: (vec3) => set({ revealElementPosition: vec3 }),
    particlesMesh: null,
    setParticlesMesh: (mesh) => set({ particlesMesh: mesh }),

    resetFade: false,
    setResetFade: (value) => set({ resetFade: value }),

    resetButtonClicked: false,
    setResetButtonClicked: (value) => set({ resetButtonClicked: value }),

    reset: () => {
      set((state) => ({ resetFade: true }));
      set((state) => ({ autoResetEnabled: false }));
      setTimeout(() => {
        resetDotsRevealed();
        set((state) => ({
          postEffect: false,
          activatedElements: new Array(26).fill(false),
          activeElement: -1,
          revealingElement: -1,
          activeColor: new THREE.Color("white"),
          revealPosition: [0, 0, 0],
          resetFade: false,
          resetButtonClicked: false,
        }));
      }, 500);
    },
    startKeyPressed: false,
    setStartKeyPressed: () => {
      set((state) => ({
        startKeyPressed: true,
      }));
    },
    assetPrepProgress: 0,

    reportMeshLoaded: () => {
      handleMeshLoaded();
    },
    downloadProgress: 0,
    setDownloadProgress: (prog) => {
      set((state) => ({ downloadProgress: prog / 100 }));
    },
    displayProgress: 0,
    setDisplayProgress: (prog) => {
      set((state) => ({ displayProgress: prog }));
    },
    loadComplete: false,
    setLoadComplete: (bool) => {
      set((state) => ({ loadComplete: bool }));
    },
    specialMode: false,
    setSpecialMode: (bool) => {
      set((state) => ({ specialMode: bool }));
    },
  };
});

function handleMeshLoaded() {
  let meshLoadCount = useStore.getState().meshLoadCount;

  meshLoadCount =
    meshLoadCount < meshLoadChecksum ? meshLoadCount + 1 : meshLoadChecksum;

  const progress = meshLoadCount / meshLoadChecksum / 2 + audioLoadProgress / 2;

  useStore.setState({ assetPrepProgress: progress });

  if (meshLoadCount >= meshLoadChecksum && audioLoadProgress !== 1) {
    //console.log("Audio Re-check Scheduled");
    setTimeout(handleMeshLoaded, 500);
  }
}

// function getHdri() {
//   const tex = new THREE.TextureLoader().load(hdri);
//   tex.mapping = THREE.EquirectangularReflectionMapping;
//   return tex;
// }

function randomData(count) {
  return new Array(count).fill().map(() => {
    return {
      guid: guid++,
      scale: [1, 1, 1],
      position: [0, 0, 0],
    };
  });
}

function setActivated(array, index) {
  let a = [...array];
  if (index >= 0) {
    a[index] = true;
  }
  return a;
}

const musicTrackEnabled = [false, false];
const volumes = [0, 0];
let targetVolume = 0;
let firstPlay = true;

function fadeAudio() {
  /// really dumb hack to get audio to zero so it doesn't pop the first time
  if (firstPlay) {
    let waitForAudio = false;
    for (let i = 0; i < musicPlayers.length; i++) {
      if (musicPlayers[i].getVolume() !== 0) {
        //console.log(musicPlayers[i].getVolume());
        waitForAudio = true;
        musicPlayers[i].setVolume(0);
      }
    }

    // audio volume non-zero, loop try another frame
    if (waitForAudio) requestAnimationFrame(fadeAudio);
    else {
      // volumes at zero.. ok
      firstPlay = false;
      musicPlayers.forEach((mp) => mp.play());
      requestAnimationFrame(fadeAudio);
    }
  } else {
    /// not first play
    let needUpdate = false;
    for (let i = 0; i < musicPlayers.length; i++) {
      const trackEnabled = musicTrackEnabled[i];
      const volume = volumes[i];

      if (trackEnabled && volume < targetVolume) {
        volumes[i] = Math.min(targetVolume, volumes[i] + 0.0005);
        musicPlayers[i].setVolume(volumes[i]);
        needUpdate = true;
      }

      if (!trackEnabled && volume > 0.005) {
        volumes[i] = Math.max(0, volumes[i] - 0.005);
        musicPlayers[i].setVolume(volumes[i]);

        if (volumes[i] === 0) musicPlayers[i].stop();
        needUpdate = true;
      }
    }

    if (needUpdate) requestAnimationFrame(fadeAudio);
  }
}

function playMusic(index) {
  for (let i = 0; i < musicPlayers.length; i++) {
    if (i === index) {
      musicTrackEnabled[i] = true;
    }
    if (i !== index) {
      musicTrackEnabled[i] = false;
    }

    musicPlayers[i].setVolume(volumes[i]);
    let unused = musicPlayers[i].getVolume();
    // console.log(unused);
  }

  if (!firstPlay && !musicPlayers[index].isPlaying) musicPlayers[index].play();

  targetVolume = index === 0 ? 0.075 : 0.6;

  fadeAudio();
}

export default useStore;
export { dotsRevealed };
