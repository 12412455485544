export default {
  uniforms: {
    tDiffuse: { value: null },
    tex0: { value: null },
    lastTex: { value: null },
    renderFeedback: { value: false },
  },
  vertexShader: `
    varying vec2 vUv;

    void main(){
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    
    `,
  fragmentShader: `

    uniform sampler2D tDiffuse;
    uniform sampler2D tex0;
    uniform sampler2D lastTex;

    uniform bool renderFeedback;

    varying vec2 vUv; 


    vec3 rgb2hsv(vec3 c){
        vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
        vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
        vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    
        float d = q.x - min(q.w, q.y);
        float e = 1.0e-10;
        return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
    }
    
    
    vec3 hsv2rgb(vec3 c){
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(clamp(fract(c.xxx + K.xyz), 0.0, 0.999) * 6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
    }

    void main(){

        vec2 coord = vUv * 2.0 - 1.0;
        coord = coord * 0.98;
        coord = coord * 0.5 + 0.5;

        vec4 scene = texture2D(tex0, vUv);
        if(renderFeedback){

          vec4 feedback = texture2D(lastTex, coord) ;

          feedback.rgb = rgb2hsv(feedback.rgb);
          feedback.r = fract(feedback.r + 0.01);
          feedback.rgb = hsv2rgb(feedback.rgb);

          vec3 color = mix(feedback.rgb, scene.rgb, scene.a);

          gl_FragColor = vec4(color, 1.0);
        } else {
          gl_FragColor = scene;
        }
        gl_FragColor.a = 1.0;
    }
    
    `,
};
