import React, { useCallback, useEffect, useState } from "react";
import startScreen from "../assets/ui/start_screen.jpg";
import typeOutline from "../assets/ui/type_outline.gif";
import buck from "../assets/ui/BUCK-logo.png";
import smiley from "../assets/ui/Smiley.png";
import "../App.scss";

import useStore from "./store";

function StartOverlay() {
  const [scaleFactor, setScaleFactor] = useState(window.innerWidth / 2560);

  useEffect(() => {
    window.onresize = () => setScaleFactor(window.innerWidth / 2560);
    return () => (window.onresize = null);
  }, []);

  const displayProgress = useStore((state) => state.displayProgress);

  const loadComplete = useStore((state) => state.loadComplete);
  //const loadComplete = true;

  const str = (n) => {
    return (n * scaleFactor).toString() + "px";
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          height: 1920 * scaleFactor,
          width: 1080 * scaleFactor,
          left: "50%",
          marginLeft: str(-960),
          top: "50%",
          marginTop: str(-540),
        }}
      >
        <img src={startScreen} alt="Revisualizer" width={1920 * scaleFactor} />
      </div>

      {!loadComplete && (
        <div
          style={{
            position: "absolute",
            width: 40 * scaleFactor,
            height: 40 * scaleFactor,
            left: "50%",
            marginLeft: str(-20),
            top: "50%",
            marginTop: str(336),
            animation: `spin ${2}s linear infinite`,
          }}
        >
          <img src={smiley} alt="Smiley" width={40 * scaleFactor} />
        </div>
      )}

      {loadComplete && (
        <div
          className={"fade-in"}
          style={{
            position: "absolute",
            width: 44 * scaleFactor,
            height: 44 * scaleFactor,
            left: "50%",
            marginLeft: str(-22),
            top: "50%",
            marginTop: str(334),
          }}
        >
          <img src={buck} alt="Buck" width={44 * scaleFactor} />
        </div>
      )}

      {loadComplete && (
        <div
          className={"fade-in"}
          style={{
            position: "absolute",
            height: 62 * scaleFactor,
            width: 486 * scaleFactor,
            left: "50%",
            marginLeft: str(-243),
            top: "50%",
            marginTop: str(180),
          }}
        >
          <img src={typeOutline} alt="Outline" width={486 * scaleFactor} />
        </div>
      )}

      {!loadComplete && (
        <div
          style={{
            position: "absolute",
            height: 62 * scaleFactor,
            width: 486 * scaleFactor,
            left: "50%",
            // marginLeft: "-243px",
            top: "50%",
            marginTop: str(180),
            color: "#BEBFEE",
            fontSize: 25 * scaleFactor,
            textAlign: "center",
            lineHeight: str(62),
            transform: "translate(-50%)",
          }}
        >
          LOADING {Math.round(displayProgress * 100)}%
        </div>
      )}

      {loadComplete && (
        <div
          className={"fade-in"}
          style={{
            position: "absolute",
            height: 62 * scaleFactor,
            width: 486 * scaleFactor,
            left: "50%",
            // marginLeft: "-243px",
            top: "50%",
            marginTop: str(180),
            color: "#BEBFEE",
            fontSize: 25 * scaleFactor,
            textAlign: "center",
            lineHeight: str(62),
            transform: "translate(-50%)",
          }}
        >
          PRESS SPACEBAR TO START
        </div>
      )}
    </div>
  );
}

export default StartOverlay;
