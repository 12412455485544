import React from "react";
import useStore from "./store";
import { useEffect, useRef } from "react";

export default function BrowserWarning(props) {

	return (
		<>
			<div id="browserWarning">
				<p>For the best experience, use <a href='http://www.google.com/chrome'>Chrome</a>.</p>
			</div>
		</>
	)
}
