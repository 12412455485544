import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLoader, useFrame } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useTextureLoader } from "drei";
import useStore from "./store";
import { useSphere } from "use-cannon";
import * as THREE from "three";
import { Vector3 } from "three";
import { useCallback } from "react";

const rand = (num) => Math.random() * num - num / 2;
const emissiveBaseColor = new THREE.Color(0x303030);

export default function SwarmElement({
  index,
  startPosition,
  url,
  textures,
  color,
  size,
}) {
  const gltf = useLoader(GLTFLoader, url);
  const [diff, rough, normal] = useTextureLoader(textures);

  const meshes = [];
  const meshDark = useRef();
  const meshRev = useRef();
  const meshPost = useRef();

  const position = useRef([0, 0, 0]);
  const velocity = useRef([0, 0, 0]);
  const localPointLight = useRef();
  const angularVelocity = useRef([0, 0, 0]);
  const loaded = useRef(false);

  const [revealed, setRevealed] = useState(false);

  const activeElement = useStore(
    useCallback((state) => state.activeElement, [])
  );
  const revealingElement = useStore(
    useCallback((state) => state.revealingElement, [])
  );
  const setRevealingElement = useStore(
    useCallback((state) => state.setRevealingElement, [])
  );
  const setActiveColor = useStore(
    useCallback((state) => state.setActiveColor, [])
  );
  const revealPosition = useStore(
    useCallback((state) => state.revealElementPosition, [])
  );
  const setRevealPosition = useStore(
    useCallback((state) => state.setRevealPosition, [])
  );
  const postEffect = useStore(useCallback((state) => state.postEffect, []));

  const positionalSounds = useStore(
    useCallback((state) => state.positionalSounds, [])
  );
  const reportMeshLoaded = useStore(
    useCallback((state) => state.reportMeshLoaded, [])
  );
  const startKeyPressed = useStore((state) => state.startKeyPressed);
  const specialMode = useStore(useCallback((state) => state.specialMode, []));

  const [started, setStarted] = useState(false);
  const distanceLatch = useRef(false);

  useEffect(() => {
    if (specialMode && startKeyPressed)
      if (index !== revealingElement) physObj.current.visible = false;
  }, [specialMode, startKeyPressed]);

  // START!!
  useEffect(() => {
    if (startKeyPressed) {
      setStarted(startKeyPressed);
      show(meshDark.current);
    }
  }, [startKeyPressed]);

  const [physObj, api] = useSphere((index) => {
    let [x, y, z] = startPosition;
    const obj = {
      mass: 2,
      args: size / 10, // radius
      position: [x, y, z],
      linearDamping: 0.8,
      angularDamping: 0.5,
    };

    return obj;
  });

  const material0 = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      color: 0x050505,
      // opacity: 0,
      transparent: true,
      roughness: 0.8,
      metalness: 0.7,
    });
  }, []);

  const lightColor = useMemo(() => {
    const propColor = new THREE.Color(color).convertSRGBToLinear();
    const c = new THREE.Color();
    propColor.getHSL(c);
    const l = c.l;
    if (l < 0.5) propColor.offsetHSL(0, 0, 0.5 - l);
    //console.log(propColor.getHSL().l);
    return propColor;
  }, [color]);

  const material1 = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      transparent: true,
      map: diff,
      emissiveMap: diff,
      emissiveIntensity: 1,
      emissive: emissiveBaseColor,
      normalMap: normal,
      normalScale: new THREE.Vector2(1, 1),
      roughnessMap: rough,
    });
  }, [diff, normal, rough]);

  const material2 = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      transparent: true,
      color: 0xfffffff,
      map: diff,
      emissiveIntensity: 1,
      emissive: lightColor,
      roughnessMap: rough,
      normalMap: normal,
      normalScale: new THREE.Vector2(1, 1),
    });
  }, [diff, lightColor, normal, rough]);

  const randomStartRotation = useMemo(() => {
    return [Math.random() * 6.28, Math.random() * 6.28, Math.random() * 6.28];
  }, []);

  const idleRotation = useMemo(() => {
    const mult = postEffect ? 5 : 0.75;
    return [rand(0.03) * mult, rand(0.03) * mult, rand(0.03) * mult];
  }, [postEffect]);

  const spinAmount = useMemo(() => {
    return postEffect ? 1 : 0.1;
  }, [postEffect]);

  const show = useCallback((obj) => {
    //obj.material.opacity = 1;
    obj.scale.set(1, 1, 1);
  }, []);

  const hide = useCallback((obj) => {
    //obj.material.opacity = 0;
    obj.scale.set(0.0001, 0.0001, 0.0001);
  }, []);

  // Start opacity
  useEffect(() => {
    material0.opacity = startKeyPressed ? 1 : 0;
  }, [startKeyPressed, material0.opacity]);

  // Physics Setup
  useEffect(() => {
    api.position.subscribe((p) => {
      position.current = p;
    });
    api.velocity.subscribe((v) => {
      velocity.current = v;
    });
    api.angularVelocity.subscribe((v) => {
      angularVelocity.current = v;
    });
  }, [api.angularVelocity, api.position, api.velocity]);

  // Sound
  useEffect(() => {
    if (positionalSounds[index]) physObj.current.add(positionalSounds[index]);
  }, [positionalSounds, index, physObj]);

  // Textures
  useEffect(() => {
    diff.flipY = false;
    diff.encoding = THREE.sRGBEncoding;
    diff.needsUpdate = true;
    rough.flipY = false;
    rough.encoding = THREE.LinearEncoding;
    rough.needsUpdate = true;
    normal.flipY = false;
    normal.encoding = THREE.LinearEncoding;
    normal.needsUpdate = true;
  }, [diff, rough, normal]);

  // Active
  useEffect(() => {
    if (activeElement === index && !revealed) {
      setRevealed(true);
      setRevealingElement(index);
    }
  }, [activeElement, index, revealed, setRevealingElement]);

  // All Clicks
  useEffect(() => {
    if (activeElement >= 0 && activeElement === index) {
      // Light
      setActiveColor(lightColor); // send color to store
      if (!postEffect) {
        localPointLight.current.intensity += 10.0;
        meshRev.current.material.emissiveIntensity += 10;
      } else {
        meshPost.current.material.emissiveIntensity += 10;
      }

      // Spin
      const [xA, yA, zA] = angularVelocity.current;
      const angularMag = new THREE.Vector3(xA, yA, zA).length();
      if (angularMag < 10)
        api.applyLocalImpulse(
          [spinAmount, spinAmount, spinAmount],
          position.current
        );

      // Move
      if (!postEffect && distanceLatch.current && !specialMode) {
        const [x, y, z] = position.current;
        const mag = new THREE.Vector3(x, y, z).normalize(); // direction from center
        mag.multiplyScalar(5);
        api.applyImpulse([mag.x, mag.y, mag.z], position.current);
      }

      // Sound
      if (positionalSounds[index]) {
        const sound = positionalSounds[index];
        if (sound.isPlaying) sound.stop();
        sound.play();
      }
    }
  }, [
    activeElement,
    api,
    index,
    lightColor,
    positionalSounds,
    postEffect,
    setActiveColor,
    spinAmount,
    specialMode,
  ]);

  // Set revealing
  useEffect(() => {
    if (revealingElement === index) {
      setRevealPosition(position.current);
    }
  }, [revealingElement, index, setRevealPosition]);

  //Initial animation on start
  useEffect(() => {
    if (started) {
      // Initial push toward center
      const [x, y, z] = position.current;
      const norm = new Vector3(x, y, z).normalize().multiplyScalar(-55);
      api.applyImpulse([norm.x, norm.y, norm.z], [0, 0, 0]);
    }
  }, [started, api]);

  // Revealed
  useEffect(() => {
    if (revealed) {
      hide(meshDark.current);
      show(meshRev.current);
    } else {
      localPointLight.current.intensity = 0;
      show(meshDark.current);
      hide(meshRev.current);
    }
  }, [revealed, hide, show]);

  // Switch post mesh
  useEffect(() => {
    localPointLight.current.intensity = 0;
    if (postEffect) {
      hide(meshDark.current);
      hide(meshRev.current);
      show(meshPost.current);
    } else {
      show(meshDark.current);
      hide(meshRev.current);
      hide(meshPost.current);

      setRevealed(false);
    }
  }, [postEffect, hide, show]);

  // Repel from revealer
  useEffect(() => {
    if (revealPosition && revealingElement !== index) {
      const [x, y, z] = position.current;
      const [xR, yR, zR] = revealPosition;

      const pos = new THREE.Vector3(x, y, z);
      const rPos = new THREE.Vector3(xR, yR, zR);
      const dir = pos.clone().sub(rPos);
      const dist = dir.length();

      if (dist <= 2) {
        const ratio = (1 - dist / 2) * 4;

        api.applyImpulse(
          [dir.x * ratio, dir.y * ratio, dir.z * ratio],
          [0, 0, 0]
        );
      }
    }
  }, [revealPosition, api, index, revealingElement]);

  // grab and move point light [not used - SAVE - in case of one shared pointlight]
  // const positionPointLight = useCallback(() => {
  //   physObj.current.getWorldPosition(worldPos);
  //   localPointLight.current.position.copy(physObj.current.position);
  // }, []);

  useFrame((state, delta) => {
    // Forced mesh loaded check !!!
    if (!loaded.current) {
      if (meshPost.current instanceof THREE.Mesh && loaded.current === false) {
        reportMeshLoaded();
        loaded.current = true;
      }
    }

    if (!started) return;

    const [x, y, z] = position.current;
    const currentPos = new THREE.Vector3(x, y, z);
    const dist = new THREE.Vector3(x, y, z).length();

    if (!distanceLatch.current && dist > 4) return;
    distanceLatch.current = true;

    // Boundry return force
    if (dist > 1.75) {
      currentPos.multiplyScalar(-1.25);
      api.applyForce([currentPos.x, currentPos.y, currentPos.z], [0, 0, 0]);
    }
    // if (index === 0) {
    //   console.log(
    //     "  dist: " +
    //       dist.toFixed(2) +
    //       "pos: " +
    //       x.toFixed(2) +
    //       "," +
    //       y.toFixed(2) +
    //       "," +
    //       z.toFixed(2)
    //   );
    // }
    // Idle Spin
    const spinMult = Math.min(dist, 5);
    api.applyLocalForce(
      [
        idleRotation[0] * spinMult,
        idleRotation[1] * spinMult,
        idleRotation[2] * spinMult,
      ],
      position.current
    );

    // Revealed Mode
    if (revealed && !postEffect) {
      meshRev.current.material.emissiveIntensity = THREE.MathUtils.lerp(
        meshRev.current.material.emissiveIntensity,
        1,
        0.01
      );
      localPointLight.current.intensity = THREE.MathUtils.lerp(
        localPointLight.current.intensity,
        1,
        0.01
      );
    }

    // Post Effect Mode
    if (postEffect) {
      // time.current += delta;
      // let val = (Math.sin(time.current * 2.5) + 1) / 2;

      meshPost.current.material.emissiveIntensity = THREE.MathUtils.lerp(
        meshPost.current.material.emissiveIntensity,
        1, // + val * 1.75,
        0.05
      );
    }
  });

  for (let node in gltf.nodes) {
    if (gltf.nodes[node].type === "Mesh") {
      meshes.push(
        <group name={"Element " + index} key={gltf.nodes[node].uuid}>
          <mesh
            ref={meshDark}
            name={"Shape " + index + " Dark"}
            userData-index={index}
            receiveShadow
            castShadow
            material={material0}
            rotation={randomStartRotation}
            scale={[0.0001, 0.0001, 0.0001]}
          >
            <bufferGeometry attach="geometry" {...gltf.nodes[node].geometry} />
          </mesh>
          <mesh
            ref={meshRev}
            name={"Shape " + index + " Revealed"}
            userData-index={index}
            receiveShadow
            castShadow
            material={material1}
            rotation={randomStartRotation}
            scale={[0.0001, 0.0001, 0.0001]}
          >
            <bufferGeometry attach="geometry" {...gltf.nodes[node].geometry} />
          </mesh>
          <mesh
            ref={meshPost}
            name={"Shape " + index + " Post"}
            userData-index={index}
            material={material2}
            rotation={randomStartRotation}
            scale={[0.0001, 0.0001, 0.0001]}
          >
            <bufferGeometry attach="geometry" {...gltf.nodes[node].geometry} />
          </mesh>
        </group>
      );
    }
  }

  return (
    <>
      <group ref={physObj} name={"Element " + index}>
        {meshes}
        <pointLight
          ref={localPointLight}
          position={[0, 0, 0]}
          distance={2.5}
          intensity={0}
          color={lightColor}
          decay={2}
          visible={true}
        >
          {/* <mesh>
            <sphereBufferGeometry attach="geometry" args={[size / 10, 8, 8]} />
            <meshBasicMaterial
              attach="material"
              color={lightColor}
              wireframe
              transparent
              opacity={0.2}
            />
          </mesh> */}
        </pointLight>
      </group>
    </>
  );
}
