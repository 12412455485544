import React from "react";
import useStore from "./store";
import { useEffect, useRef } from "react";

export default function TransitionFader(props) {
	const resetFade = useStore((state) => state.resetFade);
	
	const sceneHidden = {
		opacity: '1'
	}
	
	const sceneVisible = {
		opacity: '0'
	}

	return (
		<div 
		id="fader"
		style={resetFade ? sceneHidden : sceneVisible}
		/>
	)
}
