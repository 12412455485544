import React, { useCallback } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import useStore from "./store";

export default function KeyInput(props) {
  const setActiveElement = useStore(
    useCallback((state) => state.setActiveElement, [])
  );
  const lettersArray = useStore(useCallback((state) => state.lettersArray, []));

  const setStartKeyPressed = useStore(
    useCallback((state) => state.setStartKeyPressed, [])
  );
  const startKeyPressed = useStore(
    useCallback((state) => state.startKeyPressed, [])
  );
  const loadComplete = useStore(useCallback((state) => state.loadComplete, []));
  const specialMode = useStore(useCallback((state) => state.specialMode, []));
  const setSpecialMode = useStore(
    useCallback((state) => state.setSpecialMode, [])
  );

  const handleKeyPress = useCallback(
    (key, e) => {
      if (key !== "Space" && startKeyPressed) {
        const index = lettersArray.indexOf(key);
        setActiveElement(index);
      }

      if (key === "Space" && loadComplete) setStartKeyPressed();
    },
    [
      startKeyPressed,
      loadComplete,
      lettersArray,
      setActiveElement,
      setStartKeyPressed,
    ]
  );

  return (
    <>
      <KeyboardEventHandler
        handleKeys={lettersArray}
        onKeyEvent={(key, e) => {
          handleKeyPress(key, e);
        }}
      />
      <KeyboardEventHandler
        handleEventType={"keyup"}
        handleKeys={lettersArray}
        onKeyEvent={(key, e) => {
          setActiveElement(-1);
        }}
      />

      <KeyboardEventHandler
        handleKeys={["shift+*"]}
        onKeyEvent={(key, e) => {
          setSpecialMode(!specialMode);
        }}
      />
    </>
  );
}
