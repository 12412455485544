import ReactDOM from "react-dom";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Suspense,
} from "react";
import * as THREE from "three";
import { useThree, useFrame } from "react-three-fiber";
import {
  useXR,
  DefaultXRControllers,
  useController,
  VRCanvas,
} from "@react-three/xr";
import { useStore, LoadProgress, AudioLoad } from "./components/store";
import "./AppVR.css";

import Playspace from "./components/playspace";
import KeyInput from "./components/keyInput";

const previousPositions = [new THREE.Vector3(), new THREE.Vector3()];
const gamepadValues = {
  leftStick: { x: 0, y: 0 },
  rightStick: { x: 0, y: 0 },
  leftStickPress: 0,
  rightStickPress: 0,
  leftTrigger: 0,
  rightTrigger: 0,
  leftGrip: 0,
  rightGrip: 0,
  buttonA: 0,
  buttonB: 0,
  buttonX: 0,
  buttonY: 0,
  leftVelocity: new THREE.Vector3(),
  rightVelocity: new THREE.Vector3(),
};

function App(props) {
  const audioListener = useStore(
    useCallback((state) => state.audioListener, [])
  );

  const XR_Group = useRef();
  const { gl, camera } = useThree();
  const cam = useRef(gl.xr.isPresenting ? gl.xr.getCamera(camera) : camera);
  const leftController = useController("left");
  const rightController = useController("right");

  // get camera
  useEffect(() => {
    cam.current = gl.xr.isPresenting ? gl.xr.getCamera(camera) : camera;
    if (gl.xr.isPresenting) cam.current.add(audioListener);
    XR_Group.current.add(cam.current);
    return () => XR_Group.current.remove(cam.current);
  }, [gl.xr.isPresenting, gl.xr, XR_Group, audioListener]);

  // bundle add the controllers to the same object as the camera so it all stays together.
  const { controllers } = useXR();
  useEffect(() => {
    controllers.forEach((c) => {
      XR_Group.current.add(c.grip);
    });
    return () => controllers.forEach((c) => XR_Group.current.remove(c.grip));
  }, [controllers, XR_Group]);

  // set camera position
  useEffect(() => {
    const camStartPosition = gl.xr.isPresenting
      ? new THREE.Vector3(0, 0, 0)
      : new THREE.Vector3(0, 1.2, 3.5);
    cam.current.position.copy(camStartPosition);
  }, [gl.xr.isPresenting, cam.current]);

  // update controller inputs
  useFrame((state, delta) => {
    if (leftController) {
      const input = leftController.inputSource.gamepad;
      gamepadValues.leftStick.x = input.axes[2];
      gamepadValues.leftStick.y = -input.axes[3];
      gamepadValues.leftStickPress = input.buttons[3].pressed;
      gamepadValues.leftTrigger = input.buttons[0].value;
      gamepadValues.leftGrip = input.buttons[1].value;
      gamepadValues.buttonX = input.buttons[4].pressed;
      gamepadValues.buttonY = input.buttons[5].pressed;
      //velocity
      const pos = leftController.grip.getWorldPosition(new THREE.Vector3());
      gamepadValues.leftVelocity = pos
        .clone()
        .sub(previousPositions[0])
        .divideScalar(delta);
      previousPositions[0].copy(pos);
    }
    if (rightController) {
      const input = rightController.inputSource.gamepad;
      gamepadValues.rightStick.x = input.axes[2];
      gamepadValues.rightStick.y = -input.axes[3];
      gamepadValues.rightStickPress = input.buttons[3].pressed;
      gamepadValues.rightTrigger = input.buttons[0].value;
      gamepadValues.rightGrip = input.buttons[1].value;
      gamepadValues.buttonA = input.buttons[4].pressed;
      gamepadValues.buttonB = input.buttons[5].pressed;
      //velocity
      const pos = rightController.grip.getWorldPosition(new THREE.Vector3());
      gamepadValues.rightVelocity = pos
        .clone()
        .sub(previousPositions[1])
        .divideScalar(delta);
      previousPositions[1].copy(pos);
    }
  });

  return (
    <>
      {/* <DefaultXRControllers /> */}
      <group ref={XR_Group} position={[0, 0, 0]} />
      <AudioLoad />
      <LoadProgress />
      <Suspense fallback={null}>
        <Playspace />
      </Suspense>
    </>
  );
}

function AppVR() {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: 0,
          pad: 0,
          position: "absolute",
          display: "block",
        }}
      >
        <KeyInput />
        <VRCanvas sRGB>
          <color attach="background" args={["#000000"]} />
          <App />
        </VRCanvas>
      </div>
    </>
  );
}

ReactDOM.render(<AppVR />, document.getElementById("root"));

export { AppVR, gamepadValues };
