import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useLoader, useFrame, useThree } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Hover, Select, useController, useXREvent } from "@react-three/xr";
import { useTextureLoader } from "drei";
import { useStore } from "./store";
import * as THREE from "three";

import { gamepadValues } from "../AppVR";

const hitVelocity = 1.8;
const hitSizeFactor = 15;
const resetSizeFactor = 13;
const emissiveBaseColor = new THREE.Color(0xffffff);

export default function SwarmElement({
  index,
  startPosition,
  url,
  textures,
  color,
  size,
}) {
  const gltf = useLoader(GLTFLoader, url);
  const [diff, metal, rough, normal] = useTextureLoader(textures);

  const state = useThree();
  const meshes = [];
  // const meshDark = useRef();
  const meshRev = useRef();
  const group = useRef();
  // const meshPost = useRef();

  const position = useRef([0, 0, 0]);
  const velocity = useRef([0, 0, 0]);
  const localPointLight = useRef();
  const angularVelocity = useRef([0, 0, 0]);
  const loaded = useRef(false);

  const active = useRef(false);
  const [revealed, setRevealed] = useState(true);
  const [hover, setHover] = useState(false);

  const activeElement = useStore(
    useCallback((state) => state.activeElement, [])
  );
  const setActiveElement = useStore(
    useCallback((state) => state.setActiveElement, [])
  );
  const revealingElement = useStore(
    useCallback((state) => state.revealingElement, [])
  );
  const setRevealingElement = useStore(
    useCallback((state) => state.setRevealingElement, [])
  );
  const setActiveColor = useStore(
    useCallback((state) => state.setActiveColor, [])
  );
  const revealPosition = useStore(
    useCallback((state) => state.revealElementPosition, [])
  );
  const setRevealPosition = useStore(
    useCallback((state) => state.setRevealPosition, [])
  );
  const postEffect = useStore(useCallback((state) => state.postEffect, []));

  const positionalSounds = useStore(
    useCallback((state) => state.positionalSounds, [])
  );
  const reportMeshLoaded = useStore(
    useCallback((state) => state.reportMeshLoaded, [])
  );
  const startKeyPressed = useStore((state) => state.startKeyPressed);
  const lightsOnMode = useStore(useCallback((state) => state.revealedMode, []));

  const leftController = useController("left");
  const rightController = useController("right");

  // const [physObj, api] = useSphere((index) => {
  //   let [x, y, z] = startPosition;
  //   const obj = {
  //     mass: 2,
  //     args: size / 10, // radius
  //     position: [x, y, z],
  //     linearDamping: 0.8,
  //     angularDamping: 0.5,
  //   };

  //   return obj;
  // });

  const lightColor = useMemo(() => {
    const propColor = new THREE.Color(color).convertSRGBToLinear();
    const c = new THREE.Color();
    propColor.getHSL(c);
    const l = c.l;
    if (l < 0.5) propColor.offsetHSL(0, 0, 0.5 - l);
    //console.log(propColor.getHSL().l);
    return propColor;
  }, [color]);

  // const material_dark = useMemo(() => {
  //   return new THREE.MeshStandardMaterial({
  //     color: 0x050505,
  //     // opacity: 0,
  //     transparent: true,
  //     roughness: 0.8,
  //     metalness: 0.7,
  //   });
  // }, []);

  const material_revealed = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      transparent: false,
      color: 0x404040,
      map: diff,
      emissiveMap: diff,
      emissiveIntensity: 0,
      emissive: emissiveBaseColor,
      normalMap: normal,
      normalScale: new THREE.Vector2(1, 1),
      //metalnessMap: metal,
      roughnessMap: rough,
    });
  }, [diff, normal, metal, rough]);

  // const material_bright = useMemo(() => {
  //   return new THREE.MeshStandardMaterial({
  //     transparent: true,
  //     color: 0xfffffff,
  //     map: diff,
  //     emissiveIntensity: 1,
  //     emissive: lightColor,
  //     metalnessMap: metal,
  //     roughnessMap: rough,
  //     normalMap: normal,
  //     normalScale: new THREE.Vector2(1, 1),
  //   });
  // }, [metal, diff, lightColor, normal, rough]);

  const randomStartRotation = useMemo(() => {
    return [Math.random() * 6.28, Math.random() * 6.28, Math.random() * 6.28];
  }, []);

  const idleRotation = useMemo(() => {
    const axis = new THREE.Vector3(rand(1), rand(1), rand(1));
    return new THREE.Quaternion().setFromAxisAngle(axis, rand(0.01));
  }, [postEffect]);

  const spinAmount = useMemo(() => {
    return postEffect ? 1 : 0.1;
  }, [postEffect]);

  const show = useCallback((obj) => {
    //obj.material.opacity = 1;
    obj.scale.set(1, 1, 1);
  }, []);

  const hide = useCallback((obj) => {
    //obj.material.opacity = 0;
    obj.scale.set(0.0001, 0.0001, 0.0001);
  }, []);

  const emissiveValue = useRef(0);
  // Emissive
  useEffect(() => {
    emissiveValue.current = lightsOnMode ? 0.3 : 0;
  }, [lightsOnMode]);

  // // Physics Setup
  // useEffect(() => {
  //   api.position.subscribe((p) => {
  //     position.current = p;
  //   });
  //   api.velocity.subscribe((v) => {
  //     velocity.current = v;
  //   });
  //   api.angularVelocity.subscribe((v) => {
  //     angularVelocity.current = v;
  //   });
  // }, [api.angularVelocity, api.position, api.velocity]);

  // Sound
  useEffect(() => {
    if (positionalSounds[index]) group.current.add(positionalSounds[index]);
  }, [positionalSounds, index, group]);

  // Textures
  useEffect(() => {
    diff.flipY = false;
    diff.encoding = THREE.sRGBEncoding;
    diff.needsUpdate = true;
    metal.flipY = false;
    metal.encoding = THREE.LinearEncoding;
    metal.needsUpdate = true;
    rough.flipY = false;
    rough.encoding = THREE.LinearEncoding;
    rough.needsUpdate = true;
    normal.flipY = false;
    normal.encoding = THREE.LinearEncoding;
    normal.needsUpdate = true;
  }, [diff, metal, rough, normal]);

  // Active
  // useEffect(() => {
  //   if (activeElement === index && !revealed) {
  //     setRevealed(true);
  //     setRevealingElement(index);
  //   }
  // }, [activeElement, index, revealed, setRevealingElement]);

  useEffect(() => {
    if (activeElement === index) {
      active.current = true;
      activateElement();
    }
  }, [activeElement, index]);

  const activateElement = useCallback(() => {
    if (!active.current) return;
    console.log("activated: " + index);
    //global light color
    setActiveColor(lightColor);
    emissiveValue.current = 0.5;
    meshRev.current.material.emissiveIntensity = 10;
    meshRev.current.material.color.setHex(0xffffff);

    // play sound
    if (positionalSounds[index]) {
      const sound = positionalSounds[index];
      if (sound.isPlaying) sound.stop();
      sound.play();
    }
  }, [lightColor]);

  // const onSqueezeLeft = useCallback(() => {
  //   const position = leftController.grip.getWorldPosition(new THREE.Vector3());
  //   const distance = position.distanceTo(
  //     group.current.getWorldPosition(new THREE.Vector3())
  //   );

  //   if (distance < size / 10) {
  //     active.current = true;
  //     activateElement();
  //   }
  // }, [leftController, group]);

  // const onSqueezeRight = useCallback(() => {
  //   const position = rightController.grip.getWorldPosition(new THREE.Vector3());
  //   const distance = position.distanceTo(
  //     group.current.getWorldPosition(new THREE.Vector3())
  //   );

  //   if (distance < size / 10) {
  //     active.current = true;
  //     activateElement();
  //   }
  // }, [rightController, group]);

  // useXREvent("squeezestart", onSqueezeLeft, { handedness: "left" });
  // useXREvent("squeezestart", onSqueezeRight, { handedness: "right" });
  // useXREvent("selectstart", onSqueezeLeft, { handedness: "left" });
  // useXREvent("selectstart", onSqueezeRight, { handedness: "right" });

  //All Clicks
  // useEffect(() => {
  //   if (activeElement >= 0 && activeElement === index) {
  //     // Light
  //     setActiveColor(lightColor); // send color to store
  //     // if (!postEffect) {
  //     //   localPointLight.current.intensity += 10.0;
  //     //   meshRev.current.material.emissiveIntensity += 10;
  //     // } else {
  //     //   meshPost.current.material.emissiveIntensity += 10;
  //     // }

  //     // // Spin
  //     // const [xA, yA, zA] = angularVelocity.current;
  //     // const angularMag = new THREE.Vector3(xA, yA, zA).length();
  //     // if (angularMag < 10)
  //     //   api.applyLocalImpulse(
  //     //     [spinAmount, spinAmount, spinAmount],
  //     //     position.current
  //     //   );

  //     // // Move
  //     // if (!postEffect) {
  //     //   const [x, y, z] = position.current;
  //     //   const mag = new THREE.Vector3(x, y, z).normalize(); // direction from center
  //     //   mag.multiplyScalar(5);
  //     //   api.applyImpulse([mag.x, mag.y, mag.z], position.current);
  //     // }

  //     // Sound
  //     if (positionalSounds[index]) {
  //       const sound = positionalSounds[index];
  //       if (sound.isPlaying) sound.stop();
  //       sound.play();
  //     }
  //   }
  // }, [
  //   activeElement,
  //   //api,
  //   index,
  //   lightColor,
  //   positionalSounds,
  //   postEffect,
  //   setActiveColor,
  //   spinAmount,
  // ]);

  // Set revealing
  // useEffect(() => {
  //   if (revealingElement === index) {
  //     setRevealPosition(position.current);
  //   }
  // }, [revealingElement, index, setRevealPosition]);

  //Initial animation on start
  // useEffect(() => {
  //   if (startKeyPressed) {
  //     // Initial push toward center
  //     const [x, y, z] = position.current;
  //     const norm = new Vector3(x, y, z).normalize().multiplyScalar(-35);
  //     api.applyImpulse([norm.x, norm.y, norm.z], [0, 0, 0]);
  //   }
  // }, [startKeyPressed, api]);

  // Revealed
  // useEffect(() => {
  //   if (revealed) {
  //     // hide(meshDark.current);
  //     show(meshRev.current);
  //   } else {
  //     localPointLight.current.intensity = 0;
  //     // show(meshDark.current);
  //     hide(meshRev.current);
  //   }
  // }, [revealed, hide, show]);

  // // Switch post mesh
  // useEffect(() => {
  //   localPointLight.current.intensity = 0;
  //   if (postEffect) {
  //     hide(meshDark.current);
  //     hide(meshRev.current);
  //     show(meshPost.current);
  //   } else {
  //     show(meshDark.current);
  //     hide(meshRev.current);
  //     hide(meshPost.current);

  //     setRevealed(false);
  //   }
  // }, [postEffect, hide, show]);

  // Repel from revealer
  // useEffect(() => {
  //   if (revealPosition && revealingElement !== index) {
  //     const [x, y, z] = position.current;
  //     const [xR, yR, zR] = revealPosition;

  //     const pos = new THREE.Vector3(x, y, z);
  //     const rPos = new THREE.Vector3(xR, yR, zR);
  //     const dir = pos.clone().sub(rPos);
  //     const dist = dir.length();

  //     if (dist <= 2) {
  //       const ratio = (1 - dist / 2) * 4;

  //       api.applyImpulse(
  //         [dir.x * ratio, dir.y * ratio, dir.z * ratio],
  //         [0, 0, 0]
  //       );
  //     }
  //   }
  // }, [revealPosition, api, index, revealingElement]);

  // grab and move point light [not used - SAVE - in case of one shared pointlight]
  // const positionPointLight = useCallback(() => {
  //   physObj.current.getWorldPosition(worldPos);
  //   localPointLight.current.position.copy(physObj.current.position);
  // }, []);
  let distanceL = 1000;
  let distanceR = 1000;
  useFrame((state, delta) => {
    // Forced mesh loaded check !!!
    if (!loaded.current) {
      if (meshRev.current instanceof THREE.Mesh && loaded.current === false) {
        reportMeshLoaded();
        loaded.current = true;
      }
    }

    group.current.quaternion.multiply(idleRotation);

    meshRev.current.material.emissiveIntensity = THREE.MathUtils.lerp(
      meshRev.current.material.emissiveIntensity,
      emissiveValue.current,
      delta * 2
    );

    if (!state.gl.xr.isPresenting) return;

    if (leftController && gamepadValues.leftVelocity.length() > hitVelocity) {
      const positionL = leftController.grip.getWorldPosition(
        new THREE.Vector3()
      );
      distanceL = positionL.distanceTo(
        group.current.getWorldPosition(new THREE.Vector3())
      );
    }
    if (rightController && gamepadValues.rightVelocity.length() > hitVelocity) {
      const positionR = rightController.grip.getWorldPosition(
        new THREE.Vector3()
      );

      distanceR = positionR.distanceTo(
        group.current.getWorldPosition(new THREE.Vector3())
      );
    }
    if (
      !active.current &&
      (distanceL < size / hitSizeFactor || distanceR < size / hitSizeFactor)
    ) {
      active.current = true;
      activateElement();
    }
    if (
      distanceL > size / resetSizeFactor &&
      distanceR > size / resetSizeFactor
    ) {
      active.current = false;
    }
    // const [x, y, z] = position.current;
    // const currentPos = new THREE.Vector3(x, y, z);
    // const dist = new THREE.Vector3(x, y, z).length();
    // Physics -- >
    // if (startKeyPressed) {
    //   // Boundry return force
    //   if (dist > 1.75) {
    //     currentPos.multiplyScalar(-1);
    //     api.applyForce([currentPos.x, currentPos.y, currentPos.z], [0, 0, 0]);
    //   }
    // }
    // // Idle Spin
    // const spinMult = Math.min(dist, 5);
    // api.applyLocalForce(
    //   [
    //     idleRotation[0] * spinMult,
    //     idleRotation[1] * spinMult,
    //     idleRotation[2] * spinMult,
    //   ],
    //   position.current
    // );
    // // Revealed Mode
    // if (revealed && !postEffect) {

    //   localPointLight.current.intensity = THREE.MathUtils.lerp(
    //     localPointLight.current.intensity,
    //     1,
    //     0.01
    //   );
    // }
    // // Post Effect Mode -->
    // if (postEffect) {
    //   meshPost.current.material.emissiveIntensity = THREE.MathUtils.lerp(
    //     meshPost.current.material.emissiveIntensity,
    //     1, // + val * 1.75,
    //     0.05
    //   );
    // }

    // if (index === 25)
    //   console.log(
    //     "calls: " +
    //       state.gl.info.render.calls +
    //       " triangles: " +
    //       state.gl.info.render.triangles
    //   );
  });

  // build mesh
  for (let node in gltf.nodes) {
    if (gltf.nodes[node].type === "Mesh") {
      meshes.push(
        <group name={"Element " + index} key={gltf.nodes[node].uuid}>
          <mesh
            scale={[0.75, 0.75, 0.75]}
            ref={meshRev}
            name={"Shape " + index + " Revealed"}
            userData-index={index}
            receiveShadow={false}
            castShadow={false}
            material={material_revealed}
            rotation={randomStartRotation}
          >
            <bufferGeometry attach="geometry" {...gltf.nodes[node].geometry} />
          </mesh>
          <mesh>
            <sphereBufferGeometry
              attach="geometry"
              args={[size / hitSizeFactor, 8, 8]}
            />
            <meshLambertMaterial
              attach="material"
              color={lightColor}
              emissive={lightColor}
              wireframe
              transparent
              opacity={0.05}
            />
          </mesh>
        </group>
      );
    }
  }

  //ref={physObj} <-- group
  return (
    <>
      <group name={"Element " + index} position={startPosition} ref={group}>
        {meshes}
      </group>
    </>
  );
}

const rand = (num) => Math.random() * num - num / 2;
