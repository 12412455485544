import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
  Suspense,
} from "react";
import { applyProps, useFrame, useThree } from "react-three-fiber";
import { useController } from "@react-three/xr";
import * as THREE from "three";
import { useStore } from "./store";
import { Sphere } from "drei";

import SwarmElement from "./swarmElement";
import { gamepadValues } from "../AppVR";

const CAM_RANGE = { MIN: 6, MAX: 10 };

const leftLastFrame = new THREE.Vector3();
const rightLastFrame = new THREE.Vector3();
const mouseDif = new THREE.Vector3();
const rotTarget = new THREE.Vector2();
let curQuaternion;
let rotateStartPoint = new THREE.Vector3(0, 0, 1);
let rotateEndPoint = new THREE.Vector3(0, 0, 1);
const rotationSpeed = 2;
let deltaX = 0,
  deltaY = 0;

function Swarm({ assets, mouse, ...props }) {
  const group = useRef();
  const state = useThree();
  const [numActivated, setNumActivated] = useState(0);
  //const dollyTarget = useRef((CAM_RANGE.MAX + CAM_RANGE.MIN) / 2);

  const leftController = useController("left");
  const rightController = useController("right");

  const lettersArray = useStore(useCallback((state) => state.lettersArray, []));
  const setActiveElement = useStore(
    useCallback((state) => state.setActiveElement, [])
  );
  const activatedElements = useStore(
    useCallback((state) => state.activatedElements, [])
  );
  const startKeyPressed = useStore(
    useCallback((state) => state.startKeyPressed, [])
  );
  const audioListener = useStore(
    useCallback((state) => state.audioListener, [])
  );

  const rotationSoundPlayer = useStore(
    useCallback((state) => state.rotationSoundPlayer, [])
  );
  const particlesMesh = useStore(
    useCallback((state) => state.particlesMesh, [])
  );

  const initialPositions = useMemo(() => {
    const samples = 28;
    const points = [];
    const phi = Math.PI * (3 - Math.sqrt(5)); // # golden angle in radians

    for (let i = 0; i < samples; i++) {
      const y = 1 - (i / (samples - 1)) * 2; //# y goes from 1 to -1
      const radius = Math.sqrt(1 - y * y); // # radius at y

      const theta = phi * i; //# golden angle increment

      const x = Math.cos(theta) * radius;
      const z = Math.sin(theta) * radius;

      const point = new THREE.Vector3(x, y, z);

      //points.push(point.normalize().multiplyScalar(1));
      points.push(point);
    }

    // const positions = new Array(26).fill().map((_, index) => {
    //   // random direction
    //   let x = Math.random() - 0.5;
    //   let y = Math.random() - 0.5;
    //   let z = Math.random() - 0.5;
    //   const dir = new THREE.Vector3(x, y, z).normalize();

    //   // move out random distance
    //   dir.multiplyScalar(1.5);

    //   return [dir.x, dir.y, dir.z];
    // });

    return points;
  }, []);

  // const useRaycast = useCallback(() => {
  //   const { raycaster, mouse, camera, scene } = state;

  //   if (startKeyPressed) {
  //     raycaster.setFromCamera(mouse, camera);
  //     let intersects = raycaster.intersectObjects(scene.children, true);
  //     if (intersects.length > 0) {
  //       const index = intersects[0].object.userData.index;
  //       if (index >= 0) setActiveElement(index);
  //     }
  //   }
  // }, [startKeyPressed, state, setActiveElement]);

  // const mouseUp = useCallback(() => {
  //   setActiveElement(-1);
  // }, [setActiveElement]);

  // const onWheel = useCallback((e) => {
  //   dollyTarget.current += e.deltaY / 750;

  //   if (dollyTarget.current < CAM_RANGE.MIN)
  //     dollyTarget.current = CAM_RANGE.MIN;
  //   if (dollyTarget.current > CAM_RANGE.MAX)
  //     dollyTarget.current = CAM_RANGE.MAX;
  // }, []);

  // useEffect(() => {
  //   //state.camera.add(new THREE.PointLight());
  //   state.camera.add(audioListener);
  //   group.current.add(rotationSoundPlayer);
  //   rotationSoundPlayer.setRefDistance(CAM_RANGE.MIN);
  //   rotationSoundPlayer.setRolloffFactor(2);
  // }, [audioListener, rotationSoundPlayer, state.camera]);

  // useEffect(() => {
  //   let count = 0;
  //   activatedElements.forEach((element) => {
  //     if (element === true) count++;
  //     setNumActivated(count);
  //   });
  // }, [activatedElements]);

  // useEffect(() => {
  //   //window.addEventListener("mousedown", useRaycast);
  //   //return () => window.removeEventListener("mousedown", useRaycast);
  // }, [useRaycast]);

  // useEffect(() => {
  //   //window.addEventListener("mouseup", mouseUp);
  //   //return () => window.removeEventListener("mouseup", mouseUp);
  // }, [mouseUp]);

  // useEffect(() => {
  //   // window.addEventListener("wheel", (e) => onWheel(e));
  //   // return () => window.removeEventListener("wheel", (e) => onWheel(e));
  // }, [onWheel]);

  useFrame((state, delta) => {
    if (!startKeyPressed) return;
    // if (gamepadValues.leftGrip < 0.5 && gamepadValues.rightGrip < 0.5) return;

    //const [mouseX, mouseY, down] = mouse.current;

    // mouseDif.set(
    //   leftLastFrame.x - mouseX,
    //   rightLastFrame.y - mouseY,
    //   down === leftLastFrame.z ? 0 : 1
    // );

    // Rotate swarm

    //rotTarget.y -= mouseDif.x * 0.1;
    rotTarget.x -= gamepadValues.rightStick.x * 0.1;

    const rotTargetLength = rotTarget.length();

    rotTarget.lerp(new THREE.Vector2(0, 0), delta * 2);
    deltaX = rotTarget.x + 0.01; //* numActivated + 0.5;
    deltaY = rotTarget.y;
    handleRotation(group.current);

    // Rotate Particles
    // if (particlesMesh.current) {
    //   deltaX = rotTarget.x * 0.05;
    //   deltaY = rotTarget.y * 0.05;
    //   handleRotation(particlesMesh.current);
    // }

    if (rotationSoundPlayer && startKeyPressed) {
      const whooshVol = Math.min(1, rotTargetLength / 30);
      if (whooshVol > 0 && !rotationSoundPlayer.isPlaying)
        rotationSoundPlayer.play();
      rotationSoundPlayer.setVolume(whooshVol);
    }

    // leftLastFrame.x = mouseX;
    // leftLastFrame.y = mouseY;
    // leftLastFrame.z = down;
  });

  const SwarmElements = useMemo(() => {
    const shuffledLetters = shuffle(lettersArray);

    return assets.map((el, i) => {
      const elementIndex = lettersArray.indexOf(el.name);
      const startPos = shuffledLetters.indexOf(el.name);

      return (
        <SwarmElement
          index={elementIndex}
          key={elementIndex}
          startPosition={initialPositions[startPos]}
          url={el.mesh}
          textures={[el.diff, el.metal, el.rough, el.normal, el.gloss]}
          color={el.color}
          size={el.size}
        />
      );
    });
  }, [assets, lettersArray]);

  return (
    <>
      <group
        name={"Swarm"}
        ref={group}
        position={props.position ? props.position : [0, 1.6, 0]}
        rotation={[0, 0, 0]}
      >
        <Suspense
          fallback={
            <Sphere scale={[1, 1, 1]}>
              <meshBasicMaterial attach="material" color="#ff0000" />
            </Sphere>
          }
        >
          {SwarmElements}
        </Suspense>
      </group>
    </>
  );
}

export default Swarm;

// ARC ROTATION FUNCTIONS -->
var handleRotation = function (obj) {
  rotateEndPoint = projectOnTrackball(deltaX, deltaY);

  var rotateQuaternion = rotateMatrix(rotateStartPoint, rotateEndPoint);
  curQuaternion = obj.quaternion;
  curQuaternion.multiplyQuaternions(rotateQuaternion, curQuaternion);
  curQuaternion.normalize();
  obj.setRotationFromQuaternion(curQuaternion);

  rotateEndPoint = rotateStartPoint;
};

function projectOnTrackball(touchX, touchY) {
  var mouseOnBall = new THREE.Vector3();

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;

  mouseOnBall.set(
    THREE.MathUtils.clamp(touchX / windowHalfX, -1, 1),
    THREE.MathUtils.clamp(-touchY / windowHalfY, -1, 1),
    0.0
  );

  var length = mouseOnBall.length();

  if (length > 1.0) {
    mouseOnBall.normalize();
  } else {
    mouseOnBall.z = Math.sqrt(1.0 - length * length);
  }
  return mouseOnBall;
}

function rotateMatrix(rotateStart, rotateEnd) {
  var axis = new THREE.Vector3(),
    quaternion = new THREE.Quaternion();

  var angle = Math.acos(
    rotateStart.dot(rotateEnd) / rotateStart.length() / rotateEnd.length()
  );

  if (angle) {
    axis.crossVectors(rotateStart, rotateEnd).normalize();
    angle *= rotationSpeed;
    quaternion.setFromAxisAngle(axis, angle);
  }
  return quaternion;
}

// array shuffle
function shuffle(array) {
  const newArray = [...array];
  var currentIndex = newArray.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = newArray[currentIndex];
    newArray[currentIndex] = newArray[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  return newArray;
}
