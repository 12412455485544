import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  Suspense,
} from "react";
import * as THREE from "three";
import { useXREvent, useController } from "@react-three/xr";
import { useThree, useFrame, useLoader } from "react-three-fiber";
import { Plane, Sphere } from "drei";
import { useStore } from "./store";

import { gamepadValues } from "../AppVR";
import Swarm from "./swarm";
import { assets1 } from "./assets";
import spriteTex from "../assets/CURSOR.png";
import "../components/shaders/PsychWaveMaterial";
import { SphereBufferGeometry } from "three";
//import Particles from "./particles";

const black = new THREE.Color(0x000000);
const backgroundColor = new THREE.Color(0x000000);
const currentColor = new THREE.Color();
const defaultColor = new THREE.Color();
const { r: dR, g: dG, b: dB } = defaultColor;

export default function Playspace() {
  const lightSpriteTexture = useLoader(THREE.TextureLoader, spriteTex);

  const state = useThree();
  const leftController = useController("left");
  const rightController = useController("right");
  const handSpriteLeft = useRef();
  const handSpriteRight = useRef();
  const handLightLeft = useRef();
  const handLightRight = useRef();
  const spotlight = useRef();
  const skySphere = useRef();
  const psychMat = useRef();
  const time = useRef(0);

  const startTransitionSound = useStore(
    useCallback((state) => state.startTransitionSound, [])
  );
  const activeColor = useStore((state) => state.activeColor);
  const revealedMode = useStore(useCallback((state) => state.revealedMode, []));
  const setRevealedMode = useStore(
    useCallback((state) => state.setRevealedMode, [])
  );

  // addlights to controllers
  useEffect(() => {
    if (leftController) {
      if (revealedMode) leftController.grip.remove(handSpriteLeft.current);
      else leftController.grip.add(handSpriteLeft.current);
      handSpriteLeft.current.position.set(0, 0, -0.05);
    }
    if (rightController) {
      if (revealedMode) rightController.grip.remove(handSpriteRight.current);
      else rightController.grip.add(handSpriteRight.current);
      handSpriteRight.current.position.set(0, 0, -0.05);
    }

    return () => {
      if (leftController) leftController.grip.remove(handSpriteLeft.current);
      if (rightController) rightController.grip.remove(handSpriteRight.current);
    };
  }, [leftController, rightController, revealedMode]);

  // global active color
  useEffect(() => {
    if (!state.gl.xr.isPresenting) return;
    currentColor.copy(activeColor);
    // backgroundColor.copy(activeColor);
    // if (gamepadValues.leftTrigger == 1 || gamepadValues.leftTrigger == 1)
    spotlight.current.intensity = 10;
  }, [activeColor]);

  // useXREvent("select", onSelectLeft, { handedness: "left" });
  // useXREvent("select", onSelectRight, { handedness: "right" });

  useFrame((state, delta) => {
    // time.current += delta * 0.5;
    // psychMat.current.iTime = time.current;

    if (!state.gl.xr.isPresenting) return;

    currentColor.lerp(defaultColor, 0.3 * delta);

    handLightLeft.current.color.copy(currentColor);
    handLightRight.current.color.copy(currentColor);

    spotlight.current.color.copy(currentColor);

    spotlight.current.intensity = THREE.MathUtils.lerp(
      spotlight.current.intensity,
      0.1,
      delta * 1.2
    );
    // backgroundColor.lerp(black, 0.3 * delta);
    // state.scene.background = backgroundColor;
    setRevealedMode(gamepadValues.buttonA == 1 || gamepadValues.buttonX == 1);
  });

  // useEffect(() => {
  //   // invert sphere geometry
  //   skySphere.current.geometry.scale(-1, 1, 1);
  // }, [skySphere]);

  return (
    <>
      <sprite ref={handSpriteLeft} scale={[0.03, 0.03, 0.03]}>
        <spriteMaterial attach="material" map={lightSpriteTexture} />
        <pointLight ref={handLightLeft} decay={1} distance={0.5} />
      </sprite>
      <sprite ref={handSpriteRight} scale={[0.03, 0.03, 0.03]}>
        <spriteMaterial attach="material" map={lightSpriteTexture} />
        <pointLight ref={handLightRight} decay={1} distance={0.5} />
      </sprite>
      <ambientLight intensity={revealedMode ? 0.05 : 0} />
      <pointLight
        ref={spotlight}
        position={[0.2, 5, -0.5]}
        // angle={Math.PI / 2}
        intensity={revealedMode ? 1 : 0.1}
        distance={10}
      >
        <sprite scale={[0.1, 0.1, 0.1]}>
          <spriteMaterial attach="material" map={lightSpriteTexture} />
        </sprite>
      </pointLight>
      {/* <mesh ref={skySphere}>
        <sphereBufferGeometry attach="geometry" args={[100]} />
        <psychWaveMaterial ref={psychMat} attach="material" scale={1000} />
      </mesh> */}

      <Plane
        args={[20, 20]}
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, 6, 0]}
      >
        <meshPhongMaterial
          attach="material"
          color={0x444444}
          dithering={true}
          side={THREE.DoubleSide}
        />
      </Plane>
      <Plane args={[20, 20]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshPhongMaterial
          attach="material"
          color={0x444444}
          dithering={true}
          side={THREE.DoubleSide}
        />
      </Plane>
      {/* <Particles count={1000} size={0.007} volume={10} /> */}
      <Swarm assets={assets1} position={[0, 1.2, 0]} />
    </>
  );
}

//color rounding for lerp
// if (
//   Math.round(currentColor.r * 1000) / 1000 !==
//     Math.round(dR * 1000) / 1000 &&
//   Math.round(currentColor.g * 1000) / 1000 !==
//     Math.round(dG * 1000) / 1000 &&
//   Math.round(currentColor.b * 1000) / 1000 !== Math.round(dB * 1000) / 1000
// ) {
// ...lerp
// } else currentColor.copy(defaultColor);
