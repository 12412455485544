import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";
import useStore from "./store";

import SwarmElement from "./swarmElement";

const CAM_RANGE = { MIN: 6, MAX: 10 };

const mouseLastFrame = new THREE.Vector3();
const mouseDif = new THREE.Vector3();
const rotTarget = new THREE.Vector2();
let curQuaternion;
let rotateStartPoint = new THREE.Vector3(0, 0, 1);
let rotateEndPoint = new THREE.Vector3(0, 0, 1);
const rotationSpeed = 2;
let deltaX = 0,
  deltaY = 0;

function Swarm({ roamingLight, assets, mouse }) {
  const group = useRef();
  const state = useThree();
  const [numActivated, setNumActivated] = useState(0);
  const dollyTarget = useRef((CAM_RANGE.MAX + CAM_RANGE.MIN) / 2);

  const lettersArray = useStore(useCallback((state) => state.lettersArray, []));
  const setActiveElement = useStore(
    useCallback((state) => state.setActiveElement, [])
  );
  const activatedElements = useStore(
    useCallback((state) => state.activatedElements, [])
  );
  const startKeyPressed = useStore(
    useCallback((state) => state.startKeyPressed, [])
  );
  const audioListener = useStore(
    useCallback((state) => state.audioListener, [])
  );
  const rotationSoundPlayer = useStore(
    useCallback((state) => state.rotationSoundPlayer, [])
  );
  const particlesMesh = useStore(
    useCallback((state) => state.particlesMesh, [])
  );
  const specialMode = useStore(useCallback((state) => state.specialMode, []));

  const initialPositions = useMemo(() => {
    const positions = new Array(26).fill().map((_, index) => {
      // random direction
      let x = Math.random() - 0.5;
      let y = Math.random() - 0.5;
      let z = Math.random() - 0.5;
      const dir = new THREE.Vector3(x, y, z).normalize();

      // move out random distance
      dir.multiplyScalar(20);

      return [dir.x, dir.y, dir.z];
    });
    return positions;
  }, []);

  const useRaycast = useCallback(() => {
    const { raycaster, mouse, camera, scene } = state;

    if (startKeyPressed) {
      raycaster.setFromCamera(mouse, camera);
      let intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        const index = intersects[0].object.userData.index;
        if (index >= 0) setActiveElement(index);
      }
    }
  }, [startKeyPressed, state, setActiveElement]);

  const mouseUp = useCallback(() => {
    setActiveElement(-1);
  }, [setActiveElement]);

  const onWheel = useCallback((e) => {
    dollyTarget.current += e.deltaY / 750;
  }, []);

  useEffect(() => {
    //state.camera.add(new THREE.PointLight());
    state.camera.add(audioListener);
    group.current.add(rotationSoundPlayer);
    rotationSoundPlayer.setRefDistance(CAM_RANGE.MIN);
    rotationSoundPlayer.setRolloffFactor(2);
  }, [audioListener, rotationSoundPlayer, state.camera]);

  useEffect(() => {
    let count = 0;
    activatedElements.forEach((element) => {
      if (element === true) count++;
      setNumActivated(count);
    });
  }, [activatedElements]);

  useEffect(() => {
    //window.addEventListener("mousedown", useRaycast);
    //return () => window.removeEventListener("mousedown", useRaycast);
  }, [useRaycast]);

  useEffect(() => {
    //window.addEventListener("mouseup", mouseUp);
    //return () => window.removeEventListener("mouseup", mouseUp);
  }, [mouseUp]);

  useEffect(() => {
    window.addEventListener("wheel", (e) => onWheel(e));
    return () => window.removeEventListener("wheel", (e) => onWheel(e));
  }, [onWheel]);

  useFrame((state, delta) => {
    if (!startKeyPressed) return;

    const [mouseX, mouseY, down] = mouse.current;

    mouseDif.set(
      mouseLastFrame.x - mouseX,
      mouseLastFrame.y - mouseY,
      down === mouseLastFrame.z ? 0 : 1
    );

    // Rotate swarm
    if (down === 1) {
      rotTarget.x -= mouseDif.x * 0.1;
      rotTarget.y -= mouseDif.y * 0.1;
    }

    const rotTargetLength = rotTarget.length();

    rotTarget.lerp(new THREE.Vector2(0, 0), delta * 2);
    deltaX = !specialMode
      ? rotTarget.x + 0.1 * numActivated + 0.5
      : rotTarget.x;
    deltaY = rotTarget.y;
    handleRotation(group.current);

    // Rotate Particles
    if (particlesMesh.current) {
      deltaX = rotTarget.x * 0.05;
      deltaY = rotTarget.y * 0.05;
      handleRotation(particlesMesh.current);
    }

    if (rotationSoundPlayer && startKeyPressed) {
      const whooshVol = Math.min(1, rotTargetLength / 50);
      if (whooshVol > 0 && !rotationSoundPlayer.isPlaying)
        rotationSoundPlayer.play();
      rotationSoundPlayer.setVolume(whooshVol);
    }

    mouseLastFrame.x = mouseX;
    mouseLastFrame.y = mouseY;
    mouseLastFrame.z = down;

    // Dolly camera
    if (!specialMode)
      dollyTarget.current = THREE.MathUtils.clamp(
        dollyTarget.current,
        CAM_RANGE.MIN,
        CAM_RANGE.MAX
      );
    state.camera.position.z = THREE.MathUtils.lerp(
      state.camera.position.z,
      dollyTarget.current,
      0.1
    );
  });

  const SwarmElements = assets.map((el, i) => {
    const elementIndex = lettersArray.indexOf(el.name);

    return (
      <SwarmElement
        index={elementIndex}
        key={elementIndex}
        roamingLight={roamingLight}
        startPosition={initialPositions[i]}
        url={el.mesh}
        textures={[el.diff, el.rough, el.normal]}
        color={el.color}
        size={el.size}
      />
    );
  });

  return (
    <>
      <group name={"Swarm"} ref={group}>
        {SwarmElements}
      </group>
    </>
  );
}

export default Swarm;

// ARC ROTATION FUNCTIONS -->
var handleRotation = function (obj) {
  rotateEndPoint = projectOnTrackball(deltaX, deltaY);

  var rotateQuaternion = rotateMatrix(rotateStartPoint, rotateEndPoint);
  curQuaternion = obj.quaternion;
  curQuaternion.multiplyQuaternions(rotateQuaternion, curQuaternion);
  curQuaternion.normalize();
  obj.setRotationFromQuaternion(curQuaternion);

  rotateEndPoint = rotateStartPoint;
};

function projectOnTrackball(touchX, touchY) {
  var mouseOnBall = new THREE.Vector3();

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;

  mouseOnBall.set(
    THREE.MathUtils.clamp(touchX / windowHalfX, -1, 1),
    THREE.MathUtils.clamp(-touchY / windowHalfY, -1, 1),
    0.0
  );

  var length = mouseOnBall.length();

  if (length > 1.0) {
    mouseOnBall.normalize();
  } else {
    mouseOnBall.z = Math.sqrt(1.0 - length * length);
  }
  return mouseOnBall;
}

function rotateMatrix(rotateStart, rotateEnd) {
  var axis = new THREE.Vector3(),
    quaternion = new THREE.Quaternion();

  var angle = Math.acos(
    rotateStart.dot(rotateEnd) / rotateStart.length() / rotateEnd.length()
  );

  if (angle) {
    axis.crossVectors(rotateStart, rotateEnd).normalize();
    angle *= rotationSpeed;
    quaternion.setFromAxisAngle(axis, angle);
  }
  return quaternion;
}
