import letterA from "../assets/letters/shape_A.glb";
import letterB from "../assets/letters/shape_B.glb";
import letterC from "../assets/letters/shape_C.glb";
import letterD from "../assets/letters/shape_D.glb";
import letterE from "../assets/letters/shape_E.glb";
import letterF from "../assets/letters/shape_F.glb";
import letterG from "../assets/letters/shape_G.glb";
import letterH from "../assets/letters/shape_H.glb";
import letterI from "../assets/letters/shape_I.glb";
import letterJ from "../assets/letters/shape_J.glb";
import letterK from "../assets/letters/shape_K.glb";
import letterL from "../assets/letters/shape_L.glb";
import letterM from "../assets/letters/shape_M.glb";
import letterN from "../assets/letters/shape_N.glb";
import letterO from "../assets/letters/shape_O.glb";
import letterP from "../assets/letters/shape_P.glb";
import letterQ from "../assets/letters/shape_Q.glb";
import letterR from "../assets/letters/shape_R.glb";
import letterS from "../assets/letters/shape_S.glb";
import letterT from "../assets/letters/shape_T.glb";
import letterU from "../assets/letters/shape_U.glb";
import letterV from "../assets/letters/shape_V.glb";
import letterW from "../assets/letters/shape_W.glb";
import letterX from "../assets/letters/shape_X.glb";
import letterY from "../assets/letters/shape_Y.glb";
import letterZ from "../assets/letters/shape_Z.glb";

import diffA from "../assets/letters/textures/A1_DIFF.png";
import diffB from "../assets/letters/textures/B1_DIFF.png";
import diffC from "../assets/letters/textures/C1_DIFF.png";
import diffD from "../assets/letters/textures/D1_DIFF.png";
import diffE from "../assets/letters/textures/E1_DIFF.png";
import diffF from "../assets/letters/textures/F1_DIFF.png";
import diffG from "../assets/letters/textures/G1_DIFF.png";
import diffH from "../assets/letters/textures/H1_DIFF.png";
import diffI from "../assets/letters/textures/I1_DIFF.png";
import diffJ from "../assets/letters/textures/J1_DIFF.png";
import diffK from "../assets/letters/textures/K1_DIFF.png";
import diffL from "../assets/letters/textures/L1_DIFF.png";
import diffM from "../assets/letters/textures/M1_DIFF.png";
import diffN from "../assets/letters/textures/N1_DIFF.png";
import diffO from "../assets/letters/textures/O1_DIFF.png";
import diffP from "../assets/letters/textures/P1_DIFF.png";
import diffQ from "../assets/letters/textures/Q1_DIFF.png";
import diffR from "../assets/letters/textures/R1_DIFF.png";
import diffS from "../assets/letters/textures/S1_DIFF.png";
import diffT from "../assets/letters/textures/T1_DIFF.png";
import diffU from "../assets/letters/textures/U1_DIFF.png";
import diffV from "../assets/letters/textures/V1_DIFF.png";
import diffW from "../assets/letters/textures/W1_DIFF.png";
import diffX from "../assets/letters/textures/X1_DIFF.png";
import diffY from "../assets/letters/textures/Y1_DIFF.png";
import diffZ from "../assets/letters/textures/Z1_DIFF.png";

// import metalA from "../assets/letters/textures/A1_REFL.png";
// import metalB from "../assets/letters/textures/B1_REFL.png";
// import metalC from "../assets/letters/textures/C1_REFL.png";
// import metalD from "../assets/letters/textures/D1_REFL.png";
// import metalE from "../assets/letters/textures/E1_REFL.png";
// import metalF from "../assets/letters/textures/F1_REFL.png";
// import metalG from "../assets/letters/textures/G1_REFL.png";
// import metalH from "../assets/letters/textures/H1_REFL.png";
// import metalI from "../assets/letters/textures/I1_REFL.png";
// import metalJ from "../assets/letters/textures/J1_REFL.png";
// import metalK from "../assets/letters/textures/K1_REFL.png";
// import metalL from "../assets/letters/textures/L1_REFL.png";
// import metalM from "../assets/letters/textures/M1_REFL.png";
// import metalN from "../assets/letters/textures/N1_REFL.png";
// import metalO from "../assets/letters/textures/O1_REFL.png";
// import metalP from "../assets/letters/textures/P1_REFL.png";
// import metalQ from "../assets/letters/textures/Q1_REFL.png";
// import metalR from "../assets/letters/textures/R1_REFL.png";
// import metalS from "../assets/letters/textures/S1_REFL.png";
// import metalT from "../assets/letters/textures/T1_REFL.png";
// import metalU from "../assets/letters/textures/U1_REFL.png";
// import metalV from "../assets/letters/textures/V1_REFL.png";
// import metalW from "../assets/letters/textures/W1_REFL.png";
// import metalX from "../assets/letters/textures/X1_REFL.png";
// import metalY from "../assets/letters/textures/Y1_REFL.png";
// import metalZ from "../assets/letters/textures/Z1_REFL.png";

import normalA from "../assets/letters/textures/A1_NORM.png";
import normalB from "../assets/letters/textures/B1_NORM.png";
import normalC from "../assets/letters/textures/C1_NORM.png";
import normalD from "../assets/letters/textures/D1_NORM.png";
import normalE from "../assets/letters/textures/E1_NORM.png";
import normalF from "../assets/letters/textures/F1_NORM.png";
import normalG from "../assets/letters/textures/G1_NORM.png";
import normalH from "../assets/letters/textures/H1_NORM.png";
import normalI from "../assets/letters/textures/I1_NORM.png";
import normalJ from "../assets/letters/textures/J1_NORM.png";
import normalK from "../assets/letters/textures/K1_NORM.png";
import normalL from "../assets/letters/textures/L1_NORM.png";
import normalM from "../assets/letters/textures/M1_NORM.png";
import normalN from "../assets/letters/textures/N1_NORM.png";
import normalO from "../assets/letters/textures/O1_NORM.png";
import normalP from "../assets/letters/textures/P1_NORM.png";
import normalQ from "../assets/letters/textures/Q1_NORM.png";
import normalR from "../assets/letters/textures/R1_NORM.png";
import normalS from "../assets/letters/textures/S1_NORM.png";
import normalT from "../assets/letters/textures/T1_NORM.png";
import normalU from "../assets/letters/textures/U1_NORM.png";
import normalV from "../assets/letters/textures/V1_NORM.png";
import normalW from "../assets/letters/textures/W1_NORM.png";
import normalX from "../assets/letters/textures/X1_NORM.png";
import normalY from "../assets/letters/textures/Y1_NORM.png";
import normalZ from "../assets/letters/textures/Z1_NORM.png";

import roughA from "../assets/letters/textures/A1_ROUGH.png";
import roughB from "../assets/letters/textures/B1_ROUGH.png";
import roughC from "../assets/letters/textures/C1_ROUGH.png";
import roughD from "../assets/letters/textures/D1_ROUGH.png";
import roughE from "../assets/letters/textures/E1_ROUGH.png";
import roughF from "../assets/letters/textures/F1_ROUGH.png";
import roughG from "../assets/letters/textures/G1_ROUGH.png";
import roughH from "../assets/letters/textures/H1_ROUGH.png";
import roughI from "../assets/letters/textures/I1_ROUGH.png";
import roughJ from "../assets/letters/textures/J1_ROUGH.png";
import roughK from "../assets/letters/textures/K1_ROUGH.png";
import roughL from "../assets/letters/textures/L1_ROUGH.png";
import roughM from "../assets/letters/textures/M1_ROUGH.png";
import roughN from "../assets/letters/textures/N1_ROUGH.png";
import roughO from "../assets/letters/textures/O1_ROUGH.png";
import roughP from "../assets/letters/textures/P1_ROUGH.png";
import roughQ from "../assets/letters/textures/Q1_ROUGH.png";
import roughR from "../assets/letters/textures/R1_ROUGH.png";
import roughS from "../assets/letters/textures/S1_ROUGH.png";
import roughT from "../assets/letters/textures/T1_ROUGH.png";
import roughU from "../assets/letters/textures/U1_ROUGH.png";
import roughV from "../assets/letters/textures/V1_ROUGH.png";
import roughW from "../assets/letters/textures/W1_ROUGH.png";
import roughX from "../assets/letters/textures/X1_ROUGH.png";
import roughY from "../assets/letters/textures/Y1_ROUGH.png";
import roughZ from "../assets/letters/textures/Z1_ROUGH.png";

import glossA from "../assets/letters/textures/A1_GLOSS.png";
import glossB from "../assets/letters/textures/B1_GLOSS.png";
import glossC from "../assets/letters/textures/C1_GLOSS.png";
import glossD from "../assets/letters/textures/D1_GLOSS.png";
import glossE from "../assets/letters/textures/E1_GLOSS.png";
import glossF from "../assets/letters/textures/F1_GLOSS.png";
import glossG from "../assets/letters/textures/G1_GLOSS.png";
import glossH from "../assets/letters/textures/H1_GLOSS.png";
import glossI from "../assets/letters/textures/I1_GLOSS.png";
import glossJ from "../assets/letters/textures/J1_GLOSS.png";
import glossK from "../assets/letters/textures/K1_GLOSS.png";
import glossL from "../assets/letters/textures/L1_GLOSS.png";
import glossM from "../assets/letters/textures/M1_GLOSS.png";
import glossN from "../assets/letters/textures/N1_GLOSS.png";
import glossO from "../assets/letters/textures/O1_GLOSS.png";
import glossP from "../assets/letters/textures/P1_GLOSS.png";
import glossQ from "../assets/letters/textures/Q1_GLOSS.png";
import glossR from "../assets/letters/textures/R1_GLOSS.png";
import glossS from "../assets/letters/textures/S1_GLOSS.png";
import glossT from "../assets/letters/textures/T1_GLOSS.png";
import glossU from "../assets/letters/textures/U1_GLOSS.png";
import glossV from "../assets/letters/textures/V1_GLOSS.png";
import glossW from "../assets/letters/textures/W1_GLOSS.png";
import glossX from "../assets/letters/textures/X1_GLOSS.png";
import glossY from "../assets/letters/textures/Y1_GLOSS.png";
import glossZ from "../assets/letters/textures/Z1_GLOSS.png";

const assets1 = [
  {
    name: "a",
    mesh: letterA,
    diff: diffA,
    normal: normalA,
    rough: roughA,
    gloss: glossA,
    // metal: metalA,
    color: "#ab8813",
    size: 4,
  },
  {
    name: "b",
    mesh: letterB,
    diff: diffB,
    normal: normalB,
    rough: roughB,
    gloss: glossB,
    // metal: metalB,
    color: "#d10e06",
    size: 4,
  },
  {
    name: "c",
    mesh: letterC,
    diff: diffC,
    normal: normalC,
    rough: roughC,
    gloss: glossC,
    // metal: metalC,
    color: "#4e3593",
    size: 4,
  },
  {
    name: "d",
    mesh: letterD,
    diff: diffD,
    normal: normalD,
    rough: roughD,
    gloss: glossD,
    // metal: metalD,
    color: "#008276",
    size: 3,
  },
  {
    name: "e",
    mesh: letterE,
    diff: diffE,
    normal: normalE,
    rough: roughE,
    gloss: glossE,
    // metal: metalE,
    color: "#601a85",
    size: 3,
  },
  {
    name: "f",
    mesh: letterF,
    diff: diffF,
    normal: normalF,
    rough: roughF,
    gloss: glossF,
    // metal: metalF,
    color: "#ca4411",
    size: 5.25,
  },
  {
    name: "g",
    mesh: letterG,
    diff: diffG,
    normal: normalG,
    rough: roughG,
    gloss: glossG,
    // metal: metalG,
    color: "#c2cac9",
    size: 4,
  },
  {
    name: "h",
    mesh: letterH,
    diff: diffH,
    normal: normalH,
    rough: roughH,
    gloss: glossH,
    // metal: metalH,
    color: "#3ac421",
    size: 3.5,
  },
  {
    name: "i",
    mesh: letterI,
    diff: diffI,
    normal: normalI,
    rough: roughI,
    gloss: glossI,
    // metal: metalI,
    color: "#d1594f",
    size: 4,
  },
  {
    name: "j",
    mesh: letterJ,
    diff: diffJ,
    normal: normalJ,
    rough: roughJ,
    gloss: glossJ,
    // metal: metalJ,
    color: "#cce822",
    size: 4,
  },
  {
    name: "k",
    mesh: letterK,
    diff: diffK,
    normal: normalK,
    rough: roughK,
    gloss: glossK,
    // metal: metalK,
    color: "#c10067",
    size: 3,
  },
  {
    name: "l",
    mesh: letterL,
    diff: diffL,
    normal: normalL,
    rough: roughL,
    gloss: glossL,
    // metal: metalL,
    color: "#e07219",
    size: 4,
  },
  {
    name: "m",
    mesh: letterM,
    diff: diffM,
    normal: normalM,
    rough: roughM,
    gloss: glossM,
    // metal: metalM,
    color: "#252ad1",
    size: 4,
  },
  {
    name: "n",
    mesh: letterN,
    diff: diffN,
    normal: normalN,
    rough: roughN,
    gloss: glossN,
    // metal: metalN,
    color: "#071e7a",
    size: 4,
  },
  {
    name: "o",
    mesh: letterO,
    diff: diffO,
    normal: normalO,
    rough: roughO,
    gloss: glossO,
    // metal: metalO,
    color: "#7f26bb",
    size: 3,
  },
  {
    name: "p",
    mesh: letterP,
    diff: diffP,
    normal: normalP,
    rough: roughP,
    gloss: glossP,
    // metal: metalP,
    color: "#cbca18",
    size: 2.75,
  },
  {
    name: "q",
    mesh: letterQ,
    diff: diffQ,
    normal: normalQ,
    rough: roughQ,
    gloss: glossQ,
    // metal: metalQ,
    color: "#c58441",
    size: 3.5,
  },
  {
    name: "r",
    mesh: letterR,
    diff: diffR,
    normal: normalR,
    rough: roughR,
    gloss: glossR,
    // metal: metalR,
    color: "#009fce",
    size: 2.75,
  },
  {
    name: "s",
    mesh: letterS,
    diff: diffS,
    normal: normalS,
    rough: roughS,
    gloss: glossS,
    // metal: metalS,
    color: "#70d390",
    size: 3.5,
  },
  {
    name: "t",
    mesh: letterT,
    diff: diffT,
    normal: normalT,
    rough: roughT,
    gloss: glossT,
    // metal: metalT,
    color: "#332464",
    size: 3,
  },
  {
    name: "u",
    mesh: letterU,
    diff: diffU,
    normal: normalU,
    rough: roughU,
    gloss: glossU,
    // metal: metalU,
    color: "#e5f1a2",
    size: 3.5,
  },
  {
    name: "v",
    mesh: letterV,
    diff: diffV,
    normal: normalV,
    rough: roughV,
    gloss: glossV,
    // metal: metalV,
    color: "#73d455",
    size: 4,
  },
  {
    name: "w",
    mesh: letterW,
    diff: diffW,
    normal: normalW,
    rough: roughW,
    gloss: glossW,
    // metal: metalW,
    color: "#8c3230",
    size: 3.5,
  },
  {
    name: "x",
    mesh: letterX,
    diff: diffX,
    normal: normalX,
    rough: roughX,
    gloss: glossX,
    // metal: metalX,
    color: "#4f12ff",
    size: 4,
  },
  {
    name: "y",
    mesh: letterY,
    diff: diffY,
    normal: normalY,
    rough: roughY,
    gloss: glossY,
    // metal: metalY,
    color: "#537782",
    size: 4,
  },
  {
    name: "z",
    mesh: letterZ,
    diff: diffZ,
    normal: normalZ,
    rough: roughZ,
    gloss: glossZ,
    // metal: metalZ,
    color: "#5f5d38",
    size: 3.5,
  },
];

export { assets1 };
