import React, { useMemo, useEffect, useRef, useCallback } from "react";
import pressAnyKey from "../assets/ui/PressAnyKey_v1.png";
import { dots_on, dots_off } from "../assets/ui/index.js";
import useStore, { dotsRevealed } from "./store";
import "../App.scss";

function Dot({ index }) {
  const activatedElements = useStore((state) => state.activatedElements);
  const lettersArray = useStore(useCallback((state) => state.lettersArray, []));

  const activated = activatedElements[index];
  const dot = activated ? dots_on[index] : dots_off[index];

  const flash = useRef(false);

  const style = flash.current
    ? {
        animationName: "dotsFlash",
        animationDuration: 1,
        animationIterationCount: 1,
        width: 38,
        display: "inline-grid",
        textAlign: "center",
        verticalAlign: "middle",
      }
    : {
        width: 38,
        display: "inline-grid",
        textAlign: "center",
        verticalAlign: "middle",
      };

  const handleKeyDown = useCallback(
    (key) => {
      const keyIndex = lettersArray.indexOf(key.key.toLowerCase());
      if (keyIndex === index) {
        if (!dotsRevealed[index]) {
          dotsRevealed[index] = true;
        } else flash.current = true;
      }
    },
    [lettersArray]
  );

  const handleKeyUp = useCallback(
    (key) => {
      const keyIndex = lettersArray.indexOf(key.key.toLowerCase());
      if (keyIndex === index) flash.current = false;
    },
    [lettersArray]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);
  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => document.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  return (
    <>
      <div className={flash.current ? "dots-flash" : null} style={style}>
        <img src={dot} style={{ padding: "0px", width: 28 }} alt="dot" />
      </div>
    </>
  );
}

function Dots() {
  const postEffect = useStore((state) => state.postEffect);

  const dots = useMemo(() => {
    return new Array(26).fill().map((_, index) => {
      return <Dot key={index} index={index} />;
    });
  }, []);

  return (
    <>
      {!postEffect && (
        <div className="dotsContainer">
          <div>{dots}</div>
          <div>
            <img
              src={pressAnyKey}
              style={{ padding: "30px", width: 230 }}
              alt="Press any key A-Z"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Dots;
