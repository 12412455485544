import { extend } from "react-three-fiber";
import { shaderMaterial } from "drei";
import * as THREE from "three";

const PsychWaveMaterial = shaderMaterial(
  {
    iTime: 0,
    scale: 100,
  },
  `varying vec2 vUv;
   varying vec2 fragCoord;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    fragCoord = position.xy;
  }`,
  `
  uniform float iTime;
  uniform float scale;
  varying vec2 vUv;
  varying vec2 fragCoord;

  float f(vec3 p) 
  { 
      p.z-=iTime*.5;
      return length(cos(p)-.1*cos(9.*(p.z+.1*p.x-p.y)+iTime*2.0))-(0.9+sin(iTime)*.1); 
  }
  void main()
  {
      vec2 uv = vUv;
      float i = iTime;
      vec3 d = .5-vec3(fragCoord,1.)/scale;
      d.y-=0.2;
      d.x = abs(d.x);
      vec3 o=d;
      float l = length(d.xyz)*10.0;
      float a = atan(d.y,d.x);
      o.xy*= mat2(cos(i+sin(a+i))+10.0, sin(i*.5+a*l)*2.0, -sin(i+a),cos(i*d.z+l)+10.0);
      for(int i=0;i<40;i++){
          float m = 0.0;
     
          o+=f(o+m)*(d);
      }
      o.z = length(o*d);
      vec4 c = vec4(sin(i+abs((o-d)+length(o.xy*step(o.z,700.0))))*.3+.7,1.0);
      gl_FragColor=c;
  }`
);

extend({ PsychWaveMaterial });
