import { useCallback } from "react";
import { useProgress } from "drei";
import useStore from "./store";
import { useEffect, useRef } from "react";

export default function LoadProgress() {
  const { progress } = useProgress();
  const downloadCache = useRef(0);

  const setDownloadProgress = useStore(
    useCallback((state) => state.setDownloadProgress, [])
  );
  const assetPrepProgress = useStore(
    useCallback((state) => state.assetPrepProgress, [])
  );
  const downloadProgress = useStore(
    useCallback((state) => state.downloadProgress, [])
  );
  const setDisplayProgress = useStore(
    useCallback((state) => state.setDisplayProgress, [])
  );
  const setLoadComplete = useStore(
    useCallback((state) => state.setLoadComplete, [])
  );

  useEffect(() => {
    if (progress > downloadCache.current) downloadCache.current = progress;
    setDownloadProgress(downloadCache.current);
  }, [progress, setDownloadProgress]);

  useEffect(() => {
    const prog = (assetPrepProgress + downloadProgress) / 2;

    setDisplayProgress(downloadProgress < 1 ? downloadProgress : 0.99);

    //console.log(assetPrepProgress + " | " + downloadProgress);
    if (prog === 1) {
      setLoadComplete(true);
    }
  }, [
    downloadProgress,
    assetPrepProgress,
    setDisplayProgress,
    setLoadComplete,
  ]);

  return null;
}
