import React, { useRef, useEffect } from "react";
import { useThree, useFrame, useLoader } from "react-three-fiber";
import useStore from "./store";
import * as THREE from "three";
import spriteTex from "../assets/CURSOR.png";

const colorResetSpeed = 0.2;
const defaultColor = new THREE.Color();
const { r: dR, g: dG, b: dB } = defaultColor;
let currentColor = new THREE.Color();
const screenVec = new THREE.Vector3();
const projectedPos = new THREE.Vector3();
const mouseLastFrame = new THREE.Vector2();

function MouseLight({ mouse }) {
  const mouseLight = useRef();
  const spriteMat = useRef();
  const { camera, clock } = useThree();
  const mouseInteractFactor = useRef(1);

  const activeColor = useStore((state) => state.activeColor);
  const startKeyPressed = useStore((state) => state.startKeyPressed);
  const tex = useLoader(THREE.TextureLoader, spriteTex);

  useEffect(() => {
    currentColor.set(activeColor);
  }, [activeColor]);

  useFrame((state, delta) => {
    // lerp color, rounded
    if (
      Math.round(currentColor.r * 100) / 100 !== Math.round(dR * 100) / 100 &&
      Math.round(currentColor.g * 100) / 100 !== Math.round(dG * 100) / 100 &&
      Math.round(currentColor.b * 100) / 100 !== Math.round(dB * 100) / 100
    ) {
      currentColor.lerp(defaultColor, colorResetSpeed * delta);
    } else currentColor = defaultColor.clone();

    mouseLight.current.color.set(currentColor);
    //mouseLightMat.current.color.set(currentColor);
    spriteMat.current.color.set(currentColor);

    // project mouse position from camera
    const sinZ = Math.sin(clock.elapsedTime * 0.5);
    const sinX = Math.sin(clock.elapsedTime * 0.4);
    const sinY = Math.sin(clock.elapsedTime * 0.6);
    const targetDepth = sinZ * 2 * mouseInteractFactor.current;
    screenVec.set(
      (mouse.current[0] / window.innerWidth) * 2 - 1,
      -(mouse.current[1] / window.innerHeight) * 2 + 1,
      0.5
    );
    screenVec.unproject(camera);
    screenVec.sub(camera.position).normalize();
    //var distance = -camera.position.z / vec.z;
    const distance = (targetDepth - camera.position.z) / screenVec.z;
    projectedPos.copy(
      camera.position.clone().add(screenVec.multiplyScalar(distance))
    );

    projectedPos.x += sinX * mouseInteractFactor.current;
    projectedPos.y += sinY * mouseInteractFactor.current;

    if (
      mouse.current[0] !== mouseLastFrame.x &&
      mouse.current[1] !== mouseLastFrame.y
    )
      mouseInteractFactor.current -= 0.01;
    else mouseInteractFactor.current += 0.001;

    mouseInteractFactor.current = THREE.MathUtils.clamp(
      mouseInteractFactor.current,
      0,
      1
    );

    if (startKeyPressed) mouseLight.current.position.lerp(projectedPos, 0.03);

    mouseLastFrame.set(mouse.current[0], mouse.current[1]);
  });

  return (
    <pointLight
      ref={mouseLight}
      distance={5}
      intensity={1}
      decay={2}
      castShadow
      //visible={startKeyPressed}
      position={[-200, 200, -200]}
    >
      {/* <mesh>
        <sphereBufferGeometry attach="geometry" args={[0.02, 8, 8]} />
        <meshBasicMaterial attach="material" ref={mouseLightMat} />
      </mesh> */}
      <sprite scale={[0.1, 0.1, 1]}>
        <spriteMaterial attach="material" map={tex} ref={spriteMat} />
      </sprite>
    </pointLight>
  );
}

export default MouseLight;
