import React from "react";
import resetButton from "../assets/ui/Redo_Btn_v1.png";
import resetButtonHover from "../assets/ui/Redo_Hover_Btn_v1.png";
import useStore from "./store";

function ResetButton(props) {
  const postEffect = useStore((state) => state.postEffect);
  const reset = useStore((state) => state.reset);
  const setResetButtonClicked = useStore((state) => state.setResetButtonClicked);

  const manualReset = () => {
    reset()
    setResetButtonClicked(true)
  }

  const resetStyle = {
    
    display: 'block',
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '25px',
    bottom: '25px',
    backgroundColor: 'red',

  }

  return (
    <>
      {postEffect && (
        <div
          className={'resetButton'}
          onClick={() => manualReset()}
        />
      )}
      ;
    </>
  );
}

export default ResetButton;
