import React, {
  useRef,
  useCallback,
  useEffect,
  Suspense,
  useState,
} from "react";
//import { VRButton } from "three/examples/jsm/webxr/VRButton.js";
import { Canvas } from "react-three-fiber";
import { Physics } from "use-cannon";
import useStore from "./components/store";
import { Stats } from "drei";
import * as THREE from "three";

import "./App.scss";
import Swarm from "./components/swarm";
import MouseLight from "./components/mouseLight";
import KeyInput from "./components/keyInput";
import Dots from "./components/dots";
import PostEffects from "./components/postEffects";
import Particles from "./components/particles";
import { assets1 } from "./components/assets";
import StartOverlay from "./components/startOverlay";
import ResetButton from "./components/resetButton";
import LoadProgress from "./components/loadProgress";
import TransitionFader from "./components/transitionFader";
import BrowserWarning from "./components/browserWarning";
import { isSafari } from "react-device-detect";
//import Background from "./components/background";
///import ClickObjectInfo from "./components/dev/clickObjectInfo";

export function App() {
  const [isMobile, setIsMobile] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );

  const [browser, setBrowser] = useState();

  const postEffect = useStore(useCallback((state) => state.postEffect, []));
  const playMusic = useStore(useCallback((state) => state.playMusic, []));
  const startKeyPressed = useStore(
    useCallback((state) => state.startKeyPressed, [])
  );
  const startTransitionSound = useStore(
    useCallback((state) => state.startTransitionSound, [])
  );
  const psychoModeTransitionSound = useStore(
    useCallback((state) => state.psychoModeTransitionSound, [])
  );

  const mouse = useRef([0, 0, 0, 0]);

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    //mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2];
    mouse.current[0] = x;
    mouse.current[1] = y;
  }, []);

  const onMouseDown = useCallback(
    (state) => {
      mouse.current[2] = state && startKeyPressed ? 1 : 0;
    },
    [startKeyPressed]
  );

  useEffect(() => {
    if (startKeyPressed) {
      if (postEffect) {
        psychoModeTransitionSound();
        playMusic(1);
      } else {
        startTransitionSound();
        playMusic(0);
      }
    }
  }, [
    postEffect,
    playMusic,
    psychoModeTransitionSound,
    startTransitionSound,
    startKeyPressed,
  ]);

  useEffect(() => {}, []);

  return (
    <>
      {isMobile && (
        <div
          style={{
            color: "white",
            margin: "auto",
            padding: 0,
            display: "relative",
            position: "absolute",
            top: "50%",
            width: "100%",
            msTransform: " translateY(-50%)",
            transform: "translateY(-50%)",
            textAlign: "center",
          }}
        >
          BUCK REVISUALIZER IS A<br></br>DESKTOP EXPERIENCE ONLY
          {/* <img src={img} alt="Desktop Only" width="100%" /> */}
        </div>
      )}
      {!isMobile && (
        <>
          {!startKeyPressed && (
            <div
              style={{
                backgroundColor: "black",
                margin: 0,
                padding: 0,
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
          <div
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              pad: 0,
              position: "absolute",
              display: "block",
            }}
            onMouseMove={onMouseMove}
            onMouseDown={(e) => onMouseDown(true)}
            onMouseUp={(e) => onMouseDown(false)}
            onMouseOut={(e) => onMouseDown(false)}
          >
            {/* <Stats /> */}
            {!startKeyPressed && isSafari && <BrowserWarning />}
            <LoadProgress />
            <TransitionFader />
            <Canvas
              concurrent
              colorManagement={THREE.sRGBEncoding}
              //shadowMap
              camera={{ fov: 45 }}
              pixelRatio={devicePixelRatio}
              gl={{
                antialias: true,
                alpha: false,
                toneMapping: THREE.NoToneMapping,
                powerPreference: "high-performance",
                precision: "highp",
              }}
              //vr
              // onCreated={({ gl }) =>
              //   document.body.appendChild(VRButton.createButton(gl))
              // }
            >
              {/* <ClickObjectInfo /> */}
              {/* <Background /> */}
              <ambientLight intensity={0.05} />
              <directionalLight
                castShadow
                position={[0.2, 0.5, -0.5]}
                intensity={1}
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-camera-far={100}
                shadow-camera-left={-20}
                shadow-camera-right={20}
                shadow-camera-top={20}
                shadow-camera-bottom={-20}
                shadow-bias={0.0002}
              />
              <KeyInput />
              <Suspense fallback={null}>
                {!postEffect && <Particles count={1000} size={0.007} />}
                <Physics gravity={[0, 0, 0]}>
                  <Swarm assets={assets1} mouse={mouse} />
                </Physics>
                <MouseLight mouse={mouse} />
              </Suspense>
              {postEffect && <PostEffects renderFeedbackShader={postEffect} />}
            </Canvas>
            {startKeyPressed && <Dots />}
          </div>
          {!startKeyPressed && <StartOverlay />}
          {/* {!loadComplete && <LoadingImage />} */}
          <ResetButton />
        </>
      )}
    </>
  );
}
