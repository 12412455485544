import * as THREE from "three";
import React, { useRef, useMemo, useCallback, useEffect } from "react";
import { useFrame } from "react-three-fiber";
import useStore from "./store";

export default function Particles({ count, size }) {
  const mesh = useRef();
  const postEffect = useStore(useCallback((state) => state.postEffect, []));
  const setParticlesMesh = useStore(
    useCallback((state) => state.setParticlesMesh, [])
  );

  const dummy = useMemo(() => new THREE.Object3D(), []);

  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100;
      const factor = 1 + Math.random() * 5;
      const speed = 0.001 + Math.random() / 500;
      const xFactor = -2.5 + Math.random() * 5;
      const yFactor = -2.5 + Math.random() * 5;
      const zFactor = -2.5 + Math.random() * 5;
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 });
    }
    return temp;
  }, [count]);

  useEffect(() => {
    setParticlesMesh(mesh);
  }, [mesh, setParticlesMesh]);

  useFrame((state, delta) => {
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle;

      t = particle.t += speed / 2;
      const a = Math.cos(t) + Math.sin(t * 1) / 10;
      const b = Math.sin(t) + Math.cos(t * 2) / 10;
      const s = Math.cos(t);

      dummy.position.set(
        (particle.mx / 10) * a +
          xFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b +
          yFactor +
          Math.sin((t / 10) * factor) +
          (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b +
          zFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 3) * factor) / 10
      );
      dummy.scale.set(s, s, s);
      dummy.rotation.set(s * 5, s * 5, s * 5);
      dummy.updateMatrix();

      mesh.current.setMatrixAt(i, dummy.matrix);
    });
    mesh.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <dodecahedronBufferGeometry attach="geometry" args={[size, 0]} />
        <meshPhongMaterial
          attach="material"
          color={postEffect ? 0xff0000 : 0x383838}
          emissive={postEffect ? 0xff0000 : 0x000000}
          shininess="5"
          transparent
          opacity={postEffect ? 0.05 : 0.5}
        />
      </instancedMesh>
    </>
  );
}
