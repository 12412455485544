import * as THREE from "three";
import { useThree, extend, useFrame } from "react-three-fiber";
import React, { useMemo, useRef, useEffect, useCallback } from "react";
import useStore from "./store";
import FeedbackShader from "./shaders/RainbowFeedbackShader";
//import FeedbackShader from "./shaders/FeedbackShader";
import CopyTextureShader from "./shaders/CopyTextureShader";
import CopyRenderPassShader from "./shaders/CopyRenderPassShader";
import KeyboardEventHandler from "react-keyboard-event-handler";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
// import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
// import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
// import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader.js";
// import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader.js";

extend({
  EffectComposer,
  RenderPass,
  ShaderPass,
  // GammaCorrectionShader,
  // HorizontalBlurShader,
  // VerticalBlurShader,
});

function PostEffects(props) {
  const { renderFeedbackShader, autoResetDuration } = props;

  const { gl, scene, camera, size } = useThree();

  const resetButtonClicked = useStore((state) => state.resetButtonClicked);
  const reset = useStore((state) => state.reset);

  const timerID = useRef();

  // Render targets
  const sceneRenderTarget = useMemo(
    () =>
      new THREE.WebGLRenderTarget(size.width, size.height, {
        type: THREE.FloatType,
      }),
    []
  );
  const shaderRenderTarget = useMemo(
    () =>
      new THREE.WebGLRenderTarget(size.width, size.height, {
        type: THREE.FloatType,
      }),
    []
  );
  const lastShaderRenderTarget = useMemo(
    () =>
      new THREE.WebGLRenderTarget(size.width, size.height, {
        type: THREE.FloatType,
      }),
    []
  );
  const finalFXRenderTarget = useMemo(
    () =>
      new THREE.WebGLRenderTarget(size.width, size.height, {
        type: THREE.FloatType,
      }),
    []
  );

  // Composers
  const sceneComposer = useRef();
  const shaderComposer = useRef();
  const lastShaderComposer = useRef();
  const outputComposer = useRef();

  // useEffect(() => {
  //   sceneComposer.current.setSize(size.width, size.height);
  //   shaderComposer.current.setSize(size.width, size.height);
  //   outputComposer.current.setSize(size.width, size.height);
  //   lastShaderComposer.current.setSize(size.width, size.height);
  // }, [size.height, size.width]);

  useEffect(() => void sceneComposer.current.setSize(size.width, size.height), [
    size,
  ]);
  useEffect(
    () => void shaderComposer.current.setSize(size.width, size.height),
    [size]
  );
  useEffect(
    () => void outputComposer.current.setSize(size.width, size.height),
    [size]
  );
  useEffect(
    () => void lastShaderComposer.current.setSize(size.width, size.height),
    [size]
  );

  // Start postEffects auto-reset timer
  useEffect(() => {
    timerID.current = setTimeout(function(){
        reset()
    }, 20000);

    return () => { clearTimeout(timerID.current) }

  },[]);

  // Cancel auto-reset timer if user clicks reset
  useEffect(() => {
    if(resetButtonClicked){
      clearTimeout(timerID.current)
    }

  },[resetButtonClicked]);

  const handleKey = useCallback((k) => {
// key was pressed!!
clearTimeout(timerID.current);

timerID.current = setTimeout(function(){
  reset()
}, 12000);

  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
  }, [handleKey]);

  useFrame(() => {
    sceneComposer.current.render();
    shaderComposer.current.render();
    lastShaderComposer.current.render();
    outputComposer.current.render();
  }, 1);

  // First Composer just renders the scene to the a texture
  // Second composer does the feedback effect
  // Third composer copies the feedback effect so that we can access the previous frame
  // Fourth composer outputs to screen
  return (
    <>
      <effectComposer
        ref={sceneComposer}
        args={[gl, sceneRenderTarget]}
        renderToScreen={false}
      >
        <renderPass attachArray="passes" args={[scene, camera]} />

        <shaderPass
          attachArray="passes"
          args={[CopyRenderPassShader]}
          needsSwap={false}
        />
      </effectComposer>

      <effectComposer
        ref={shaderComposer}
        args={[gl, shaderRenderTarget]}
        renderToScreen={false}
      >
        <shaderPass
          attachArray="passes"
          args={[FeedbackShader]}
          uniforms-tex0-value={sceneRenderTarget.texture}
          uniforms-lastTex-value={lastShaderRenderTarget.texture}
          uniforms-renderFeedback-value={renderFeedbackShader}
          clear={false}
        />
        {/* <shaderPass
          attachArray="passes"
          args={[HorizontalBlurShader]}
          uniforms-h-value={0.00025}
        />
        <shaderPass
          attachArray="passes"
          args={[VerticalBlurShader]}
          uniforms-v-value={0.00025}
        /> */}
      </effectComposer>

      <effectComposer
        ref={lastShaderComposer}
        args={[gl, lastShaderRenderTarget]}
        renderToScreen={false}
      >
        <shaderPass
          attachArray="passes"
          args={[CopyTextureShader]}
          uniforms-tAdd-value={shaderRenderTarget.texture}
          needsSwap={false}
        />
      </effectComposer>

      <effectComposer
        ref={outputComposer}
        args={[gl, finalFXRenderTarget]}
        renderToScreen
      >
        <shaderPass
          attachArray="passes"
          args={[CopyTextureShader]}
          uniforms-tAdd-value={shaderRenderTarget.texture}
          needsSwap={true}
        />

        {/* <unrealBloomPass attachArray="passes" args={[aspect, 1, 1, 0.9]} /> */}
        {/* <shaderPass
          attachArray="passes"
          args={[GammaCorrectionShader]}
        /> */}

        <shaderPass
          attachArray="passes"
          args={[FXAAShader]}
          uniforms-resolution-value={[1 / size.width, 1 / size.height]}
        />
      </effectComposer>
    </>
  );
}

export default PostEffects;
