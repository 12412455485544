import on0 from "./dots_on/0_on.png";
import on1 from "./dots_on/1_on.png";
import on2 from "./dots_on/2_on.png";
import on3 from "./dots_on/3_on.png";
import on4 from "./dots_on/4_on.png";
import on5 from "./dots_on/5_on.png";
import on6 from "./dots_on/6_on.png";
import on7 from "./dots_on/7_on.png";
import on8 from "./dots_on/8_on.png";
import on9 from "./dots_on/9_on.png";
import on10 from "./dots_on/10_on.png";
import on11 from "./dots_on/11_on.png";
import on12 from "./dots_on/12_on.png";
import on13 from "./dots_on/13_on.png";
import on14 from "./dots_on/14_on.png";
import on15 from "./dots_on/15_on.png";
import on16 from "./dots_on/16_on.png";
import on17 from "./dots_on/17_on.png";
import on18 from "./dots_on/18_on.png";
import on19 from "./dots_on/19_on.png";
import on20 from "./dots_on/20_on.png";
import on21 from "./dots_on/21_on.png";
import on22 from "./dots_on/22_on.png";
import on23 from "./dots_on/23_on.png";
import on24 from "./dots_on/24_on.png";
import on25 from "./dots_on/25_on.png";

import off0 from "./dots_off/0_off.png";
import off1 from "./dots_off/1_off.png";
import off2 from "./dots_off/2_off.png";
import off3 from "./dots_off/3_off.png";
import off4 from "./dots_off/4_off.png";
import off5 from "./dots_off/5_off.png";
import off6 from "./dots_off/6_off.png";
import off7 from "./dots_off/7_off.png";
import off8 from "./dots_off/8_off.png";
import off9 from "./dots_off/9_off.png";
import off10 from "./dots_off/10_off.png";
import off11 from "./dots_off/11_off.png";
import off12 from "./dots_off/12_off.png";
import off13 from "./dots_off/13_off.png";
import off14 from "./dots_off/14_off.png";
import off15 from "./dots_off/15_off.png";
import off16 from "./dots_off/16_off.png";
import off17 from "./dots_off/17_off.png";
import off18 from "./dots_off/18_off.png";
import off19 from "./dots_off/19_off.png";
import off20 from "./dots_off/20_off.png";
import off21 from "./dots_off/21_off.png";
import off22 from "./dots_off/22_off.png";
import off23 from "./dots_off/23_off.png";
import off24 from "./dots_off/24_y_off.png";
import off25 from "./dots_off/25_z_off.png";

const dots_on = [
  on0,
  on1,
  on2,
  on3,
  on4,
  on5,
  on6,
  on7,
  on8,
  on9,
  on10,
  on11,
  on12,
  on13,
  on14,
  on15,
  on16,
  on17,
  on18,
  on19,
  on20,
  on21,
  on22,
  on23,
  on24,
  on25,
];

const dots_off = [
  off0,
  off1,
  off2,
  off3,
  off4,
  off5,
  off6,
  off7,
  off8,
  off9,
  off10,
  off11,
  off12,
  off13,
  off14,
  off15,
  off16,
  off17,
  off18,
  off19,
  off20,
  off21,
  off22,
  off23,
  off24,
  off25,
];
export { dots_on, dots_off };
